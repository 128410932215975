import React from 'react';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import emptyImage from 'images/empty-proposal.png';

EmptyProposalsList.propTypes = {
  onClickNewProposal: PropTypes.func
};

function EmptyProposalsList({ onClickNewProposal }) {
  return (
    <div className='d-flex flex-column text-center justify-content-center'>
      <img className='mx-auto mb-4' src={emptyImage} alt='Empty proposal' />

      <h4 className='mb-4'>Nenhuma proposta criada para este cliente</h4>

      <p className='mb-5 text-dark-gray'>Crie e envie propostas rapidamente</p>

      <div>
        <Button onClick={onClickNewProposal}>Iniciar nova proposta</Button>
      </div>
    </div>
  );
}

export default EmptyProposalsList;
