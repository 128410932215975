import React from 'react';
import ProgressBar from 'react-bootstrap/ProgressBar';
import Stack from 'react-bootstrap/Stack';

import Badge from '@/components/Badge';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import OngoingChecklistItem from '@/feature/growth/OngoingChecklistItem';

import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useOngoingChecklist } from '@/api';
import { calculatePercentage } from '@/number';

function OngoingChecklistDropdown() {
  const { user } = useAuth();
  const tracker = useTracking();
  const onToggle = (nextShow) => {
    if (nextShow) {
      tracker.trackOngoingChecklistOpened({ user });
    }
  };

  const { data: items = [], isLoading } = useOngoingChecklist({
    config: {
      select: ({ data }) => data
    }
  });

  const totalCount = items.length;
  const completedCount = items.filter((item) => item.completed).length;
  const percentage = calculatePercentage(completedCount, totalCount, true);

  return (
    <Dropdown
      align='end'
      onToggle={onToggle}
    >
      <Dropdown.Toggle
        variant='transparent-dark'
        aria-label={`Checklist (${totalCount - completedCount})`}
      >
        <Icon name='news' />

        {
          (!isLoading && totalCount > completedCount) && (
            <Badge
              variant='flat-red'
              className='position-absolute pe-none top-0 end-0 rounded-circle icon-sm me-1'
            >
              {totalCount - completedCount}
            </Badge>
          )
        }
      </Dropdown.Toggle>

      <Dropdown.Menu className='p-6 z-index-1060' renderOnMount>
        <Stack gap={6}>
          <Stack gap={2}>
            <h4>Comece por aqui no Agendor</h4>

            <Stack direction='horizontal' gap={2}>
              <div className='text-dark-gray'>
                {percentage}%
              </div>

              <ProgressBar
                variant='primary'
                className='flex-1'
                min={0}
                max={totalCount}
                now={completedCount}
              />
            </Stack>

            <div className='text-dark-gray'>
              Continue sua exploração e aprenda de maneira rápida e fácil o
              poder do nosso CRM.
            </div>
          </Stack>

          <Stack gap={1}>
            {
              items.map((item) => (
                <OngoingChecklistItem key={item.key} data={item} />
              ))
            }
          </Stack>
        </Stack>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default OngoingChecklistDropdown;
