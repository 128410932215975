import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Button from '@/components/Button';
import LoadSpinner from '@/components/LoadSpinner';

const propTypes = {
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string
};

const defaultProps = {
  isLoading: false,
  loadingText: 'Salvando...'
};

const LoadingButton = React.forwardRef((props, ref) => {
  const { isLoading, loadingText, children, disabled, ...rest } = props;

  const isDisabled = isLoading || disabled;
  const buttonContent = children || 'Salvar';
  const content = isLoading
    ? (
      <div className='d-flex align-items-center justify-content-center'>
        <LoadSpinner
          size='sm'
          className={classnames('my-n1', { 'me-1': loadingText })}
        />
        {loadingText}
      </div>
    )
    : buttonContent;

  return (
    <Button { ...rest } ref={ref} disabled={isDisabled}>
      {content}
    </Button>
  );
});

LoadingButton.displayName = 'LoadingButton';
LoadingButton.defaultProps = defaultProps;
LoadingButton.propTypes = propTypes;

export default LoadingButton;
