import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';

import BillingFooter from '@/components/Billing/BillingFooter';
import Button from '@/components/Button';
import Card from '@/components/Card';
import ExternalLink from '@/components/ExternalLink';
import Icon from '@/components/Icon';
import PaymentMethodForm from '@/components/Billing/PaymentMethodForm';
import PixCompletion from '@/components/Billing/PixCompletion';

import { applyMask } from '@/masks';
import { useCheckoutContext } from '@/contexts';
import { useCreateTransaction, useTransaction } from '@/api';
import { parseDate, toDayOfWeek, toTextualDateString } from '@/date';
import ThreeDSecureModal from '@/components/Billing/ThreeDSecureModal';

import checkImage from 'images/check.svg';

const REFETCH_INTERVAL = 5000;
const PAYMENT_DATA = {
  paymentMethod: { id: null }
};

const PAYMENT_TYPE_IDS = {
  credit_card: 13,
  bank_slip: 14,
  pix: 15
};

const propTypes = {
  show: PropTypes.bool
};

const defaultProps = {
  show: true
};

function PaymentData({ onClose }) {
  const {
    prevStep,
    setLeadsPaymentProcess,
    setPaymentStatus,
    checkoutResumeData,
    productId
  } = useCheckoutContext();

  const [paymentType, setPaymentType] = useState('credit_card');
  const [paymentData, setPaymentData] = useState(PAYMENT_DATA);
  const [paymentCompletionData, setPaymentCompletionData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [transactionId, setTransactionId] = useState(null);
  const [threeDSecureUrl, setThreeDSecureUrl] = useState(null);
  const [showThreeDSecureModal, setShowThreeDSecureModal] = useState(false);

  const createTransactionMutation = useCreateTransaction();

  const alert = useAlert();

  const isPix = paymentType === 'pix';
  const isBankSlip = paymentType === 'bank_slip';
  const isCreditCard = paymentType === 'credit_card';

  const isAdvanceStepEnable =
    isPix ||
    isBankSlip ||
    (paymentData.paymentMethod.id) ||
    (paymentData.stripe && paymentData?.event?.complete);

  const handleCreditCardPaymentError = () => {
    alert.show(
      <div>
        <h6>Infelizmente, não conseguimos processar seu pagamento</h6>
        <div>Por favor, tente novamente ou adicione um novo cartão.</div>
      </div>,
      { variant: 'danger' }
    );
  };

  const getConfirmParams = () => ({
    product_id: productId,
    quantity: checkoutResumeData.maxUsersCount,
    additional_data: checkoutResumeData.additionalData
  });

  const getPixConfirmParams = () => ({
    ...getConfirmParams(),
    payment_type_id: PAYMENT_TYPE_IDS.pix
  });

  const getBankSlipConfirmParams = () => ({
    ...getConfirmParams(),
    payment_type_id: PAYMENT_TYPE_IDS.bank_slip
  });

  const getCreditCardConfirmParams = async () => {
    let paymentMethodId = paymentData.paymentMethod.id;

    if (paymentData.stripe) {
      const { error, paymentMethod } = await paymentData.stripe.createPaymentMethod(
        {
          elements: paymentData.elements,
          params: {
            billing_details: {
              address: {
                country: 'BR'
              }
            }
          }
        }
      );

      if (error) {
        console.error(error);
        setIsLoading(false);

        return handleCreditCardPaymentError();
      }

      paymentMethodId = paymentMethod.id;
    }

    return {
      ...getConfirmParams(),
      payment_method_id: paymentMethodId,
      payment_type_id: PAYMENT_TYPE_IDS.credit_card
    };
  };

  const confirmPayment = (params) => {
    createTransactionMutation.mutate(params, {
      onSuccess: ({ data }) => {
        setTransactionId(data.id);
      },
      onError: () => {
        setIsLoading(false);
        setLeadsPaymentProcess('error');
      }
    });
  };

  const onConfirmPayment = async () => {
    setIsLoading(true);

    let confirmParams = null;

    if (isPix) {
      confirmParams = getPixConfirmParams();
    } else if (isBankSlip) {
      confirmParams = getBankSlipConfirmParams();
    } else {
      confirmParams = await getCreditCardConfirmParams();
    }

    confirmPayment(confirmParams);
  };

  const handleCreditCard = (url) => {
    setThreeDSecureUrl(url);
    setShowThreeDSecureModal(true);
  };

  useTransaction({
    billingTransactionId: transactionId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      enabled: Boolean(isLoading && transactionId),
      onSuccess: ({ data }) => {
        switch (data.statusName) {
          case 'Aguardando Pagto':
            return;
          case 'Em análise':
            if (isCreditCard && data.transactionDetails.three_d_secure_url) {
              const decodedUrl = decodeURIComponent(data.transactionDetails.three_d_secure_url);
              handleCreditCard(decodedUrl);
            } else if (isPix) {
              const details = data.transactionDetails;

              setPaymentCompletionData({
                qrCode: details.qr_code,
                expiresAt: details.expires_at,
                value: data.value
              });
            } else if (isBankSlip) {
              const details = data.transactionDetails;

              setPaymentCompletionData({
                pdf: details.pdf,
                number: details.number,
                expiresAt: details.expires_at
              });
            }

            return;
          case 'Aprovado':
          case 'Completo':
            setIsLoading(false);
            setPaymentStatus('success');
            break;
          default:
            setIsLoading(false);
            setPaymentStatus('error');
        }
      }
    }
  });

  const renderPaymentSection = () => (
    <PaymentMethodForm
      paymentType={paymentType}
      setPaymentType={setPaymentType}
      setPaymentData={setPaymentData}
      isCreditCardSubscription={false}
    />
  );

  const renderCompletionSection = () => {
    const CompletionComponent = isPix ? PixCompletion : BankSlipCompletion;

    return (
      <CompletionComponent
        data={paymentCompletionData}
      />
    );
  };

  return (
    <>
      {paymentCompletionData ? renderCompletionSection() : renderPaymentSection()}

      {
        (
          paymentCompletionData
            ? <BillingFooter.Close onClick={onClose} />
            : (
              <BillingFooter
                buttonNextText={'Finalizar'}
                onClickNext={onConfirmPayment}
                onClickPrev={prevStep}
                disabled={!isAdvanceStepEnable}
                isLoading={isLoading}
                loadingTextButton='Finalizando...'
              />
            )
        )
      }

      <ThreeDSecureModal
        show={showThreeDSecureModal}
        url={threeDSecureUrl}
        onHide={() => setShowThreeDSecureModal(false)}
      />
    </>
  );
}

function BankSlipCompletion({ data }) {
  const expirationDate = parseDate(data.expiresAt);

  return (
    <Card className='p-4 border-light align-items-center'>
      <img className='width-3 mb-2' src={checkImage} />

      <h4 className='mb-2'>Boleto foi gerado</h4>

      <div className='text-dark-gray text-center'>
        Quando o pagamento for processado, seus créditos serão adicionados.
      </div>

      <hr className='w-100 mt-5 mb-6 text-dark-gray' />

      <div className='d-flex flex-column align-items-center text-small fw-bold text-dark-gray mb-4'>
        <div className='mb-1'>Cód. do boleto</div>

        <h4 className='mb-1 text-body text-center'>{applyMask(data.number, 'bank_slip')}</h4>

        <div>Vencimento: {toDayOfWeek(expirationDate)}, {toTextualDateString(expirationDate)}</div>
      </div>

      <div className='d-flex align-items-center mb-4'>
        <CopyToClipboard text={data.number}>
          <Button
            variant='outline-dark-gray'
            className='me-2'
          >
            <Icon name='copy' size='sm' />

            <span className='ms-2'>Copiar cód. do boleto</span>
          </Button>
        </CopyToClipboard>

        <Button
          as={ExternalLink}
          target='_blank'
          href={data.pdf}
        >
          <Icon name='download' size='sm' />

          <span className='ms-2'>Baixar boleto</span>
        </Button>
      </div>
    </Card>
  );
}

PaymentData.propTypes = propTypes;
PaymentData.defaultProps = defaultProps;

export default PaymentData;
