import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import { canCreateCustomField } from '@/policies';
import CustomFieldFormModal from '@/components/CustomFieldFormModal';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  section: PropTypes.string.isRequired,
  mutation: PropTypes.object.isRequired,
  entity: PropTypes.string.isRequired,
  className: PropTypes.string
};

const defaultProps = {};

function CreateCustomFields({ section, mutation, entity, className }) {
  const alert = useAlert();
  const tracker = useTracking();
  const { user } = useAuth();

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleFormSuccess = ({ data }) => {
    alert.show(
      'Campo customizado criado!',
      { variant: 'success', timeout: 5000 }
    );
    tracker.trackCustomFieldCreated({ user, entity, field: data });
    handleCloseModal();
  };

  return (
    <div className={className} id={`create-custom-fields-${section}`}>
      <div className='ms-0'>
        <Authorization policy={canCreateCustomField}>
          <Button
            onClick={handleOpenModal}
            variant='link'
            className='fw-bold px-0'
          >
            <Icon
              name='add'
              size='sm'
              className='me-2'
            />
            Adicionar campo
          </Button>
        </Authorization>
      </div>
      <CustomFieldFormModal
        title='Criar campo customizado'
        show={showModal}
        mutation={mutation}
        initialData={{ section }}
        onFormSuccess={handleFormSuccess}
        onHide={handleCloseModal}
        entity={entity}
      />
    </div>
  );
}

CreateCustomFields.propTypes = propTypes;
CreateCustomFields.defaultProps = defaultProps;

export default CreateCustomFields;
