import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import { dealsKeys } from '@/api';

export const activitiesKeys = {
  details: () => ['detail'],
  detail: (activityId) => [...activitiesKeys.details(), activityId],
  infiniteAll: ['infinite-activities'],
  infiniteLists: () => [...activitiesKeys.infiniteAll, 'list'],
  infiniteListsByEntity: (entity) => ([...activitiesKeys.infiniteLists(), entity]),
  infiniteListByEntity: (entity, params) => ([...activitiesKeys.infiniteLists(), entity, params])
};

export async function getActivities(params = {}) {
  const { data } = await get('/activities', params);

  return data;
}

export async function getActivity(activityId) {
  const { data } = await get(`/activities/${activityId}`);

  return data;
}

export async function createActivity(params = {}) {
  const { data } = await post('/activities', params);

  return data;
}

export async function updateActivity({ activityId, params = {} }) {
  const { data } = await patch(`/activities/${activityId}`, params);

  return data;
}

export async function deleteActivity({ activityId }) {
  const { data } = await _delete(`/activities/${activityId}`);

  return data;
}

export function useCreateActivity({ config = {}, entityType, hasProposal = false } = {}) {
  const queryClient = useQueryClient();

  const getEntityId = (data) => {
    switch (entityType) {
      case 'deal':
        return data.deal.id;
      case 'person':
        return data.person.id;
      case 'organization':
        return data.organization.id;
      default:
        return null;
    }
  };

  const meta = {
    ongoingChecklistKey: hasProposal ? 'create-proposal' : 'create-task'
  };

  const onSuccess = async ({ data: newData }) => {
    if (hasProposal && newData.proposal?.deal_id) {
      await queryClient.invalidateQueries(dealsKeys.detail(newData.proposal?.deal_id));
    }
    const key = activitiesKeys.infiniteListsByEntity({
      entityType,
      entityId: getEntityId(newData)
    });

    queryClient.setQueriesData(key, (oldData) => {
      oldData?.pages[0]?.data.unshift(newData);

      return oldData;
    });
  };

  return useMutation(
    createActivity,
    {
      onSuccess,
      meta,
      ...config
    }
  );
}

export function useUpdateActivity({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(activitiesKeys.infiniteLists);
  };

  return useMutation(
    updateActivity,
    {
      onSuccess,
      ...config
    }
  );
}

export function useDeleteActivity({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(activitiesKeys.infiniteLists);
  };

  return useMutation(
    deleteActivity,
    {
      onSuccess,
      ...config
    }
  );
}

export function useInfiniteActivities({ entityType, entityId, params = {}, config = {} } = {}) {
  return useInfiniteQuery(
    activitiesKeys.infiniteListByEntity({ entityType, entityId: Number(entityId) }, params),
    ({ pageParam: page = 1 }) => getActivities({
      ...params,
      page,
      entity: entityType,
      entity_id: entityId
    }),
    {
      ...config,
      getNextPageParam: (lastPage) => lastPage.meta.next
    }
  );
}

export function useActivity({ activityId, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: activitiesKeys.detail(activityId),
    queryFn: () => getActivity(activityId)
  });
}
