import { parseDate } from '@/date';
export default class Task {
  static TYPES = {
    visit: 'Visita',
    meeting: 'Reunião',
    call: 'Ligação',
    email: 'E-mail',
    proposal: 'Proposta',
    task: 'Tarefa',
    note: 'Nota',
    whatsapp: 'WhatsApp'
  };

  static build(data) {
    if (!data) {
      return null;
    }

    return new Task(data);
  }

  constructor(data) {
    this.data = data;
    this.type = data.type;
    this.typeName = Task.TYPES[data.type];
    this.createdAt = parseDate(data.createdAt);
    this.createdBy = data.createdBy;
    this.dueAt = data.dueAtUtc ? parseDate(data.dueAtUtc) : null;
    this.allDay = data.allDay;
    this.text = data.text;
    this.assignedUsers = data.assignedUsers;
    this.status = data.status;
  }

  get overdue() {
    return this.status === 'overdue';
  }

  get done() {
    return this.status === 'done';
  }

  get description() {
    if (!this.status || this.done) {
      return `${this.typeName}`;
    }

    let descriptionAdjective = 'pendente';

    if (!this.overdue) {
      descriptionAdjective = ['email', 'whatsapp'].includes(this.type)
        ? 'agendado'
        : 'agendada';
    }

    return `${this.typeName} ${descriptionAdjective}`;
  }
}
