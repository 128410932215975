import React from 'react';
import { Outlet } from 'react-router-dom';
import Icon from '@/components/Icon';
import NavLink from '@/components/NavLink';
import Tabs from '@/components/Tabs';
import * as Settings from '@/feature/settings';
import { settingsCustomFieldsPath } from '@/routes';
import { useDocumentTitle } from '@/hooks';
import Main from '@/components/Main';

const PAGE_TITLE = 'Personalização de formulários';
const PAGE_SUBTITLE = `Configure os formulários de cadastro e
  adapte o Agendor a sua forma de trabalhar.`;

function CustomFieldsBase() {
  useDocumentTitle(PAGE_TITLE);

  return (
    <Main>
      <Settings.PageTitle>
        {PAGE_TITLE}
      </Settings.PageTitle>

      <Settings.PageSubtitle>
        {PAGE_SUBTITLE}
      </Settings.PageSubtitle>

      <Tabs className='border-bottom border-1'>
        <Tabs.Item>
          <NavLink href={settingsCustomFieldsPath('organizations')} end>
            <Icon name='organization' className='me-2' /> Empresa
          </NavLink>
        </Tabs.Item>

        <Tabs.Item>
          <NavLink href={settingsCustomFieldsPath('people')} end>
            <Icon name='person' className='me-2' /> Pessoa
          </NavLink>
        </Tabs.Item>

        <Tabs.Item>
          <NavLink href={settingsCustomFieldsPath('deals')} end>
            <Icon name='deal' className='me-2' /> Negócio
          </NavLink>
        </Tabs.Item>
      </Tabs>
      <Outlet />
    </Main>
  );
}

export default CustomFieldsBase;
