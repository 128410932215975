import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Experiment,
  Variant,
  emitter,
  experimentDebugger
} from '@marvelapp/react-ab-test';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';

const propTypes = {
  name: PropTypes.string.isRequired,
  identifier: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

experimentDebugger.enable();

function ABTest({
  name,
  children,
  identifier
}) {
  const tracker = useTracking();
  const { user } = useAuth();

  useEffect(() => {
    const variantName = emitter.getActiveVariant(name);
    tracker.trackABTest({
      user,
      experimentName: name,
      variantName
    });
  }, []);

  /**
   *
   * Caso seja passado um identificador, prefixa o nome do teste para que
   * um usuário com o mesmo identificador não seja sempre atribuído à mesma
   * variante de todos os testes, levando a interferência.
   *
   * Está sendo feita exceção para o teste 'funnel_gift' porque ele já tinha
   * sido iniciado e essa mudança faria os usuários potencialmente mudarem de
   * grupo. Quando ele for encerrado, essa exceção pode ser retirada.
   *
   */
  const userIdentifier = (identifier && name !== 'funnel_gift')
    ? `${name}${identifier}`
    : identifier;

  return (
    /**
     *
     * A prop `userIdentifier` pode ser usada para calcular a variante de forma
     * determinística com base em algum identificador do usuário.
     * Ou seja, com o mesmo identificador, sempre é calculada a mesma variante.
     * Isso é útil, por exemplo, para colocarmos todos os usuários de uma mesma
     * conta numa mesma variante.
     *
     * Mesmo usando o `userIdentifier` é possível alterar a variante escolhida
     * editando o `localStorage` ou localmente pelo debugger, para testes.
     * Porém, ao usar o debugger, existe um bug no componente base em que,
     * quando é usado um  `userIdentifier` e a variante é trocada de volta para
     * a variante calculada localmente, ocorre um loop infinito de re-renders.
     * ref: https://github.com/marvelapp/react-ab-test/issues/24#issuecomment-1546917899
     */
    <Experiment name={name} userIdentifier={userIdentifier}>
      {children}
    </Experiment>
  );
}

ABTest.propTypes = propTypes;

ABTest.Variant = Variant;

export default ABTest;
