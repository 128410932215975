import React from 'react';
import PropTypes from 'prop-types';
import sum from 'lodash/sum';
import clone from 'lodash/clone';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from '@/components/Card';
import { EditInPlace, TextInput, HookProductsFields } from '@/components/Inputs';
import { toCurrencyString, toPercentageString, calculatePercentage } from '@/number';

ProposalProducts.propTypes = {
  title: PropTypes.string,
  items: PropTypes.array,
  hiddenFields: PropTypes.object
};

ProposalProducts.defaultProps = {
  title: 'Produtos e serviços',
  items: [],
  hiddenFields: {}
};

export function ProposalProducts({ title, items, hiddenFields }) {
  if (!items?.length) {
    return null;
  }

  const nonRemovedProducts = items.filter((item) => !item._destroy);
  const valueWithoutDiscount = sum(nonRemovedProducts.map((item) => Number(item.subtotal)));
  const valueWithDiscount = sum(nonRemovedProducts.map((item) => Number(item.total_value)));

  const calculateDiscount = (subtotal, totalValue) => Number(subtotal) - Number(totalValue);
  const discountAbsolute = calculateDiscount(valueWithoutDiscount, valueWithDiscount);
  const discountPercentage = calculatePercentage(discountAbsolute, valueWithoutDiscount);

  const itemWidth = '30%';
  const unitPriceWidth = '20%';
  const quantityWidth = '15%';
  const discountWidth = '15%';
  const totalWidth = '20%';

  const summaryHiddenFields = hiddenFields?.productSummaryTotal &&
    hiddenFields?.productSummaryDiscount &&
    hiddenFields?.productSummaryTotalWithDiscount;

  return (
    <div className='d-block w-100 mx-0 my-4 text-darker-gray'>

      <hr className='m-4 proposal-hr' />

      <h2 className='fw-bold my-4 px-4'>{title ?? 'Produtos e serviços'}</h2>
      <div className='d-block p-4 mx-0' style={{ backgroundColor: '#f0f0f7' }}>
        <div className='d-inline-block' style={{ width: itemWidth }}>
          Item
        </div>
        {
          hiddenFields?.productPrice
            ? null
            : (
              <div className='d-inline-block' style={{ width: unitPriceWidth }}>
                Preço unitário
              </div>
            )
        }
        {
          hiddenFields?.productQuantity
            ? null
            : (
              <div className='d-inline-block' style={{ width: quantityWidth }}>
                Quantidade
              </div>
            )
        }
        {
          hiddenFields?.productDiscount
            ? null
            : (
              <div className='d-inline-block' style={{ width: discountWidth }}>
                Desconto
              </div>
            )
        }
        {
          hiddenFields?.productTotalWithDiscount
            ? null
            : (
              <div className='d-inline-block' style={{ width: totalWidth }}>
                Total
              </div>
            )
        }
      </div>
      {nonRemovedProducts?.map((product, index) => (
        <div
          key={`${product?.product_id}_${index}`}
          className='p-4 mx-0 d-block proposal-products-border'
        >
          <div className='d-inline-block' style={{ width: itemWidth }}>
            {product?.name || product?.initialProduct?.name}
          </div>
          {
            hiddenFields?.productPrice
              ? null
              : (
                <div className='d-inline-block' style={{ width: unitPriceWidth }}>
                  {toCurrencyString(Number(product?.unit_value))}
                </div>
              )
          }
          {
            hiddenFields?.productQuantity
              ? null
              : (
                <div className='d-inline-block' style={{ width: quantityWidth }}>
                  {product?.quantity}
                </div>
              )
          }
          {
            hiddenFields?.productDiscount
              ? null
              : (
                <div className='d-inline-block' style={{ width: discountWidth }}>
                  {toPercentageString(product?.discount_percentage)}
                </div>
              )
          }
          {
            hiddenFields?.productTotalWithDiscount
              ? null
              : (
                <div className='d-inline-block' style={{ width: totalWidth }}>
                  {toCurrencyString(Number(product?.total_value))}
                </div>
              )
          }
        </div>
      ))}
      <table style={{
        width: '100%',
        marginTop: '2rem',
        marginBottom: '1rem',
        borderCollapse: 'collapse',
        border: 'none',
        outline: 'none'
      }}
      >
        <tbody style={{ border: 'none', outline: 'none' }}>
          <tr style={{ border: 'none', outline: 'none' }}>
            <td style={{ width: '74%', border: 'none', outline: 'none' }}></td>
            <td style={{
              textAlign: 'left',
              width: '26%',
              border: 'none',
              outline: 'none'
            }}
            >
              {
                summaryHiddenFields
                  ? null
                  : (
                    <div>
                      <strong>Resumo</strong>
                    </div>
                  )
              }
              {
                hiddenFields?.productSummaryTotal
                  ? null
                  : (
                    <div>
                      <span>Valor - </span>
                      <span>{toCurrencyString(valueWithoutDiscount)}</span>
                    </div>
                  )
              }
              {
                hiddenFields?.productSummaryDiscount
                  ? null
                  : (
                    <div>
                      <span>
                        Valor com desconto ({toPercentageString(discountPercentage)}) -
                      </span>
                      <span>{toCurrencyString(discountAbsolute)}</span>
                    </div>
                  )
              }
              {
                hiddenFields?.productSummaryTotalWithDiscount
                  ? null
                  : (
                    <div>
                      <strong>Valor total - </strong>
                      <strong>{toCurrencyString(valueWithDiscount)}</strong>
                    </div>
                  )
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

ProposalProductsInput.propTypes = {
  title: PropTypes.string,
  onChangeProducts: PropTypes.func,
  actions: PropTypes.any,
  onChangeTitle: PropTypes.func,
  hiddenFields: PropTypes.object,
  onChangeFieldVisibility: PropTypes.func
};

ProposalProductsInput.defaultProps = {
  title: 'Produtos e serviços',
  onChangeProducts: () => { },
  onChangeTitle: () => { },
  actions: null,
  hiddenFields: {},
  onChangeFieldVisibility: () => { }
};

export function ProposalProductsInput({
  title, onChangeProducts, onChangeTitle, actions, hiddenFields, onChangeFieldVisibility
}) {
  return (
    <Row className='mx-6'>
      <Col>
        <Card className='border-light p-4 pe-2'>
          <div className='d-flex'>
            <EditInPlace
              className='width-5'
              name='title'
              as={TextInput}
              value={title}
              placeholder='Dê um título para a seção'
              onConfirm={(params, done) => {
                onChangeTitle(params?.title);
                done();
              }}
            >
              <h2 className='text-medium fw-bold d-block'>
                {title ?? 'Produtos e serviços'}
              </h2>
            </EditInPlace>
            <div className='d-flex align-items-end justify-content-end flex-grow-1 pe-2'>
              {actions}
            </div>
          </div>
          <span className='text-dark-gray my-2'>
            Adicione produtos ou serviços com valor e quantidade na sua oportunidade de venda.
          </span>
          <div className='d-flex my-2 max-height-6 overflow-y-scroll'>
            <HookProductsFields
              onChange={(products) => onChangeProducts(clone(products))}
              hiddenFields={hiddenFields}
              onChangeFieldVisibility={onChangeFieldVisibility}
              showToggleVisibility
            />
          </div>
        </Card>
      </Col>
    </Row>
  );
}
