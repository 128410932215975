import React from 'react';
import SortInput from '@/components/Inputs/SortInput';
import PropTypes from 'prop-types';


const propTypes = {
  value: PropTypes.string.isRequired
};

const defaultProps = {
  value: 'created_at'
};

function ProposalSortInput(props) {
  const { value, ...rest } = props;

  return (
    <SortInput
      value={value}
      {...rest}
      options={[
        {
          label: 'Data de Criação',
          value: 'created_at',
          direction: { type: 'date', defaultValue: 'desc' }
        },
        {
          label: 'Ordem Alfabética',
          value: 'nome',
          direction: { type: 'text', defaultValue: 'asc' }
        }
      ]}
    />
  );
}

ProposalSortInput.propTypes = propTypes;
ProposalSortInput.defaultProps = defaultProps;

export default ProposalSortInput;
