import React from 'react';

import { AutomationCard, Section, getAutomationSections } from '@/feature/automation';
import { useAutomations } from '@/api';

function AutomationsIndex() {
  const { data: sections = {} } = useAutomations({
    config: {
      select: ({ data }) => getAutomationSections(data)
    }
  });

  return (
    <div>
      {Object.entries(sections).map(([sectionName, automations]) => (
        <Section key={sectionName} name={sectionName}>
          {automations.map((automation) => (
            <AutomationCard key={automation.id} data={automation} />
          ))}
        </Section>
      ))}
    </div>
  );
}

export default AutomationsIndex;
