import React from 'react';
import { Navigate, useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import { useAlert } from 'react-alert';
import APIErrorMessage from '@/components/APIErrorMessage';
import { AutomationForm, templates, getAutomationName } from '@/feature/automation';
import { useCreateAutomation } from '@/api';
import { settingsAutomationTemplatesPath, settingsAutomationsPath } from '@/routes';
import { useTracking } from '@/lib/tracking';
import { useAuth } from '@/lib/auth';
import { getValidationErrors } from '@/utils';
import { useAutomationContext } from '@/pages/settings/automations/modal';

function AutomationsNew() {
  const location = useLocation();
  const type = location.state?.type;
  const template = templates[type];

  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();
  const navigate = useNavigate();
  const { onHide } = useOutletContext();
  const createMutation = useCreateAutomation();

  const onSubmit = (values, { setFieldError }) => (
    createMutation.mutateAsync(values, {
      onSuccess: () => {
        alert.show(
          'Automação criada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );

        const automationName = getAutomationName(template);
        tracker.trackAutomationCreated({
          user,
          automationName,
          actionParams: values.action_params
        });

        navigate(settingsAutomationsPath());
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'automations');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='automation' action='create' />,
          { variant: 'danger' }
        );
      }
    })
  );

  const { initialValues } = useAutomationContext();

  if (!template) {
    return <Navigate to={settingsAutomationTemplatesPath()} />;
  }

  return (
    <AutomationForm
      template={template}
      initialValues={initialValues}
      onSubmit={onSubmit}
      onCancel={onHide}
      title='Criar nova automação'
      saveButtonLabel='Salvar'
    />
  );
}

export default AutomationsNew;
