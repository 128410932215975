import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useProposals } from '@/api';
import { parseDate, toTextualDateString } from '@/date';
import { downloadJSONFromUrl, downloadBlob, PDF_EXTENSION, IMAGE_EXTENSIONS } from '@/utils';
import { useAuth } from '@/lib/auth';
import TableCell from '@/components/TableCell';
import LoadingButton from '@/components/LoadingButton';
import EntitySkeleton from '@/components/List/EntitySkeleton';
import EmptyProposalsList from '@/components/Proposal/EmptyProposalsList';
import StoredForm from '@/components/StoredForm';
import FormField from '@/components/FormField';
import Icon from '@/components/Icon';
import Dropdown from '@/components/Dropdown';
import Avatar from '@/components/Avatar';
import ProposalSortInput from '@/components/Proposal/ProposalSortInput';
import ProposalPDF from '@/components/Proposal/ProposalPDF';
import ProposalRenameModal from '@/components/Proposal/ProposalRenameModal';
import Table from '@/components/Table';
import CreateEmailModal from '@/components/Email/CreateEmailModal';
import FeatureControlPopover from '@/components/FeatureControlPopover';
import { createUppy, useUppy } from '@/lib/uppy';
import { compact } from 'lodash';

const propTypes = {
  deal: PropTypes.object.isRequired,
  relatedEntity: PropTypes.object,
  onLoadingMetadata: PropTypes.func,
  onClickNewProposal: PropTypes.func,
  onClickEditProposal: PropTypes.func,
  onSaveNewProposalModel: PropTypes.func
};

const defaultProps = {
  deal: {},
  relatedEntity: {},
  onLoadingMetadata: () => {},
  onClickNewProposal: () => {},
  onClickEditProposal: () => { },
  onSaveNewProposalModel: () => { }
};

const PDF_TYPE = 'application/pdf';
const PDF_MAX_SIZE = 10;
const IMAGES_MAX_SIZE = 50;

function CreatedProposalList({
  deal,
  relatedEntity,
  onLoadingMetadata,
  onClickNewProposal,
  onClickEditProposal,
  onSaveNewProposalModel
}) {
  const { user } = useAuth();
  const [process, setProcess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [proposalId, setProposalId] = useState(null);
  const [download, setDownload] = useState(false);
  const [selectedProposal, setSelectedProposal] = useState(null);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shareProposalEmail, setShareProposalEmail] = useState(null);
  const [params, setParams] = useState({ deal_id: deal.id });

  const { data = [], isFetching: isLoadingProposals } = useProposals({ params });
  const proposals = data?.data || [];

  const hasFullAccess = user.account.legacyPro || user.account.performanceOrHigher;
  const hasItems = Boolean(proposals.length);
  const handleSort = (values) => {
    setParams((prevParams) => ({ ...prevParams, sorts: values.sorts }));
  };

  const createCustomUppy = (allowedFileTypes, maxFileSizeMega) => {
    const newUppy = createUppy({ allowedFileTypes, maxFileSizeMega, maxNumberOfFiles: 1 });
    return newUppy;
  };

  const uploadFile = async (customUppy, name, content, type) => {
    customUppy.reset();
    await customUppy.addFile({ name, type, data: new Blob([content], { type }) });
    const result = await customUppy.upload();
    const uploaded = result.successful?.map((file) => file.response.body)?.[0];
    return uploaded;
  };

  const pdfUppy = useUppy(() => createCustomUppy([PDF_EXTENSION], PDF_MAX_SIZE));
  const logoUppy = useUppy(() => createCustomUppy(IMAGE_EXTENSIONS, IMAGES_MAX_SIZE));
  const bannerUppy = useUppy(() => createCustomUppy(IMAGE_EXTENSIONS, IMAGES_MAX_SIZE));

  const loadProposalImages = async (proposal) => {
    let banner;
    const bannerBlob = await downloadBlob(proposal?.banner?.url);

    if (proposal?.banner?.id && bannerBlob) {
      const filename = proposal.banner.id.split('/').pop();
      const type = bannerBlob.type;
      banner = await uploadFile(bannerUppy, filename, bannerBlob, type);
    }

    let logo;
    const logoBlob = await downloadBlob(proposal?.logo?.url);

    if (proposal?.logo?.id && logoBlob) {
      const filename = proposal.logo.id.split('/').pop();
      const type = logoBlob.type;
      logo = await uploadFile(logoUppy, filename, logoBlob, type);
    }

    return { banner, logo };
  };

  const loadProposalMetadata = async (proposal) => {
    const metadata = await downloadJSONFromUrl(proposal?.metadata?.url);
    return metadata;
  };

  const openEmailModal = async (proposalFile) => {
    const attachment = await uploadFile(
      pdfUppy,
      selectedProposal?.filename,
      proposalFile?.blob,
      PDF_TYPE
    );
    const to = compact([relatedEntity?.contact?.email]);

    setShareProposalEmail({ attachments: [attachment], to });
    setShowEmailModal(true);
  };

  const handleCloseEmailModal = () => {
    pdfUppy.reset();
    logoUppy.reset();
    bannerUppy.reset();

    setShowEmailModal(false);
    setShareProposalEmail(null);
    setProcess(false);
    setLoading(false);
    setProposalId(null);
    setDownload(false);
  };

  const handleCreatedPdf = async (proposalFile) => {
    setProcess(false);
    if (download && proposalFile) {
      setLoading(false);
      setProposalId(null);
      window.open(proposalFile?.preview, '_blank');
    }
    if (!download && proposalFile) {
      setLoading(false);
      setProposalId(null);
      await openEmailModal(proposalFile);
    }
  };

  const handleDownloadProposalPDF = async (proposal) => {
    const metadata = await loadProposalMetadata(proposal);
    setLoading(true);
    setProposalId(proposal.id);
    setDownload(true);
    setSelectedProposal({
      ...proposal,
      ...metadata,
      version: proposal.name,
      filename: `${proposal.name}.pdf`
    });
    setProcess(true);
  };

  const handleSendProposalViaEmail = async (proposal) => {
    const metadata = await loadProposalMetadata(proposal);
    setLoading(true);
    setProposalId(proposal.id);
    setSelectedProposal({
      ...proposal,
      ...metadata,
      version: proposal.name,
      filename: `${proposal.name}.pdf`
    });
    setProcess(true);
    setDownload(false);
  };

  const handleEditProposal = (proposal) => {
    onLoadingMetadata(async () => {
      const metadata = await loadProposalMetadata(proposal);
      // Carrega e envia proposta para abrir modal de edição
      const mountedProposal = {
        ...metadata,
        ...proposal,
        id: null,
        version: proposal.name,
        filename: `${proposal.name}.pdf`
      };

      onClickEditProposal(mountedProposal);
    });
  };

  const handleSetNameForNewProposalModel = async (proposal) => {
    if (hasFullAccess) {
      const metadata = await loadProposalMetadata(proposal);
      const { banner, logo } = await loadProposalImages(proposal);

      // Cria uma cópia de modelo proposta com base na proposta selecionada
      const mountedProposal = {
        ...metadata,
        ...proposal,
        banner,
        logo,
        id: null,
        version: proposal.name,
        filename: `${proposal.name}.pdf`
      };

      setSelectedProposal(mountedProposal);
      setShowRenameModal(true);
    }
  };

  const handleSaveNewProposalModel = ({ name }) => {
    setShowRenameModal(false);
    setLoading(false);
    setProcess(false);
    setProposalId(null);
    onSaveNewProposalModel({ ...selectedProposal, name });
  };

  const handleCloseRenameModal = () => {
    setShowRenameModal(false);
    setLoading(false);
    setProcess(false);
    setProposalId(null);
  };

  const renderSendProposalDropdown = (proposal) => (
    <Dropdown>
      <Dropdown.Toggle
        as={LoadingButton}
        isLoading={loading && proposalId === proposal.id}
        loadingText='Aguarde...'
        variant='transparent-light'
        className='d-inline-flex align-items-center'
      >
        <Icon name='send' size='sm' className='me-2 text-primary' />
        Enviar
      </Dropdown.Toggle>
      <Dropdown.Menu
        id='send-menu'
        className='width-5'
        popperConfig={{ strategy: 'fixed' }}
        renderOnMount
      >
        <Dropdown.Divider />
        <Dropdown.Item
          as='button'
          className='fw-bold text-darker-gray'
          onClick={() => handleSendProposalViaEmail(proposal)}
        >
          <Icon name='email' className='me-2' />
          Enviar por e-mail
        </Dropdown.Item>
        <Dropdown.Divider />
        <Dropdown.Item
          as='button'
          className='fw-bold text-darker-gray'
          onClick={() => handleDownloadProposalPDF(proposal)}
        >
          <Icon name='download' className='me-2' />
          Baixar PDF
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const renderActionDropdown = (proposal) => (
    <Dropdown>
      <Dropdown.Toggle
        variant='transparent-light'
        className='d-inline-flex align-items-center'
      >
        <Icon name='vertical-dots' className='text-darker-gray' />
      </Dropdown.Toggle>

      <Dropdown.Menu
        id='action-menu'
        renderOnMount
        popperConfig={{ strategy: 'fixed' }}
      >
        <Dropdown.Item
          as='button'
          className='fw-bold text-darker-gray'
          onClick={() => handleEditProposal(proposal)}
        >
          <Icon name='edit' className='me-2' />
          Editar
        </Dropdown.Item>
        <Dropdown.Divider />
        <FeatureControlPopover
          user={user}
          showPopover={!hasFullAccess}
          placement={'left'}
        >
          <div
            className='fw-bold px-4 py-3 cursor-pointer hover-bg-light rounded-bottom'
            onClick={() => handleSetNameForNewProposalModel(proposal)}
          >
            <Icon name='proposal' className='me-2' />
            Salvar como modelo
          </div>
        </FeatureControlPopover>
      </Dropdown.Menu>
    </Dropdown>
  );

  return (
    <>
      <div className='d-flex align-items-center'>
        <h3>Propostas criadas nesse negócio</h3>
        <div className='d-flex flex-grow-1 justify-content-end'>
          {
            hasItems
              ? (
                <StoredForm
                  name='proposals-sort'
                  initialValues={{ sorts: params?.sorts }}
                  onSubmit={handleSort}
                >
                  {({ submitForm, handleChange, values }) => (
                    <>
                      <FormField
                        as={ProposalSortInput}
                        name='sorts'
                        value={values.sorts}
                        innerClassName='mx-3'
                        onChange={(e) => {
                          handleChange(e);
                          submitForm();
                        }}
                      />
                    </>
                  )}
                </StoredForm>
              )
              : null
          }
        </div>
      </div>

      <div
        className='mt-6 overflow-hidden overflow-y-auto'
        style={{ minHeight: '50vh', maxHeight: '50vh' }}
      >
        <Table borderless>
          {
            hasItems
              ? (
                <thead className='sticky-top bg-white'>
                  <tr className='text-nowrap'>
                    <th>Título da proposta</th>
                    <th>Criada por</th>
                    <th>Data de criação</th>
                    <th></th>
                    <th></th>
                  </tr>
                </thead>
              )
              : null
          }
          <tbody>
            {isLoadingProposals && <EntitySkeleton maxCols={3} iconless />}
            {
              hasItems || isLoadingProposals
                ? null
                : (
                  <div className='mt-8'>
                    <EmptyProposalsList onClickNewProposal={onClickNewProposal} />
                  </div>
                )
            }
            {
              !isLoadingProposals && proposals?.map((proposal, index) => (
                <tr
                  key={index}
                  className='border-bottom hover-parent cursor-pointer hover-bg-light'
                  onClick={() => handleEditProposal(proposal)}
                >
                  <td className='fw-bold'>
                    <div className='mt-2'>
                      <Icon name='proposal' className='me-2 text-primary' />
                      <TableCell value={proposal?.name} />
                    </div>
                  </td>
                  <td>
                    <div className='mt-2 d-inline-flex align-items-center'>
                      <Avatar
                        name={proposal?.createdBy?.name}
                        url={proposal?.createdBy?.avatarUrl}
                        className='me-1'
                        tooltip={false}
                      />
                      {proposal?.createdBy?.name}
                    </div>
                  </td>
                  <td>
                    <div className='mt-2'>
                      <TableCell value={toTextualDateString(parseDate(proposal?.createdAt))} />
                    </div>
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    {renderSendProposalDropdown(proposal)}
                  </td>
                  <td onClick={(e) => e.stopPropagation()}>
                    {renderActionDropdown(proposal)}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </Table>
      </div>
      {
        showEmailModal
          ? (
            <CreateEmailModal
              show={showEmailModal}
              onClose={handleCloseEmailModal}
              attachments={shareProposalEmail?.attachments}
              to={shareProposalEmail?.to}
              entityId={deal?.id}
              entityType={'deal'}
            />
          )
          : null
      }
      <ProposalRenameModal
        show={showRenameModal}
        title='Salvar modelo de proposta'
        icon='proposal'
        onSubmit={handleSaveNewProposalModel}
        onHide={handleCloseRenameModal}
        proposalModel={selectedProposal}
      />
      <ProposalPDF
        process={process}
        user={user}
        proposal={selectedProposal}
        filename={selectedProposal?.filename}
        version={selectedProposal?.version}
        onCreate={handleCreatedPdf}
      />
    </>
  );
}

CreatedProposalList.propTypes = propTypes;
CreatedProposalList.defaultProps = defaultProps;

export default CreatedProposalList;
