import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import { accessoryCallCache } from '@/utils';

export const organizationCustomFieldsKeys = {
  all: ['organization-custom-fields']
};

export async function getOrganizationCustomFields() {
  const { data } = await get('/organizations/custom_fields');

  return data;
}

export function useOrganizationCustomFields({ config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: organizationCustomFieldsKeys.all,
    queryFn: () => getOrganizationCustomFields()
  });
}

export async function createOrganizationsCustomField(params = {}) {
  const { data } = await post('/organizations/custom_fields', params);

  return data;
}

export function useCreateOrganizationsCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(organizationCustomFieldsKeys.all);
  };

  return useMutation(
    createOrganizationsCustomField,
    {
      onSuccess,
      ...config
    }
  );
}

export async function updateOrganizationsCustomField({ customFieldId, params = {} }) {
  const { data } = await patch(`/organizations/custom_fields/${customFieldId}`, params);

  return data;
}

export function useUpdateOrganizationsCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(organizationCustomFieldsKeys.all);
  };

  return useMutation(
    updateOrganizationsCustomField,
    {
      onSuccess,
      ...config
    }
  );
}

export async function updateOrganizationsCustomFieldOrder({ params = {} }) {
  const { data } = await patch('/organizations/custom_fields/batch', params);

  return data;
}

export function useUpdateOrganizationsCustomFieldOrder({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(organizationCustomFieldsKeys.all);
  };

  return useMutation(
    updateOrganizationsCustomFieldOrder,
    {
      onSuccess,
      ...config
    }
  );
}

export async function deleteOrganizationsCustomField({ customFieldId }) {
  const { data } = await _delete(`/organizations/custom_fields/${customFieldId}`);

  return data;
}

export function useDeleteOrganizationsCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(organizationCustomFieldsKeys.all);
  };

  return useMutation(
    deleteOrganizationsCustomField,
    {
      onSuccess,
      ...config
    }
  );
}
