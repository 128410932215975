import React from 'react';
import PropTypes from 'prop-types';

ProposalLogo.propTypes = {
  src: PropTypes.string
};

ProposalLogo.defaultProps = {
  src: ''
};

function ProposalLogo({ src }) {
  return (
    <div className='width-4 height-4 mb-4 rounded overflow-hidden mx-auto'>
      {
        src
          ? (
            <img
              className='w-100 h-100 object-fit-cover rounded'
              src={src}
              alt='logotipo'
            />
          )
          : (
            <div className='w-100 h-100 p-8 bg-white text-dark-gray'>
              <span className='proposal-icon-org proposal-md' />
            </div>
          )
      }
    </div>
  );
}

export default ProposalLogo;
