import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import shuffle from 'lodash/shuffle';

import { useUpdateProfile } from '@/api';
import NeedInputCard from '@/feature/profile_form/NeedInputCard';
import OtherInputCard from '@/feature/profile_form/OtherInputCard';
import { useAuth } from '@/lib/auth';
import HookForm from '@/components/HookForm';
import Badge from '@/components/Badge';
import FooterButtons from '@/feature/profile_form/FooterButtons';
import HookFormFeedback from '@/components/HookFormFeedback';
import HookFormField from '@/components/HookFormField';
import ToggleButtonGroup from '@/components/ToggleButtonGroup';
import { useTracking } from '@/lib/tracking';

const propTypes = {
  advanceStep: PropTypes.func,
  returnStep: PropTypes.func,
  stepPosition: PropTypes.number,
  totalSteps: PropTypes.number
};

const defaultProps = {
  advanceStep: () => {},
  returnStep: () => {},
  stepPosition: 0,
  totalSteps: 0
};

const schema = Yup.object().shape({
  user: Yup.object().shape({
    need: Yup.object().shape({
      checks: Yup.array(Yup.string()).min(1, 'Por favor, escolha pelo menos uma opção.'),
      other: Yup.string()
    })
  })
});

const options = [
  {
    icon: '👥',
    title: 'Organização de contatos',
    text: 'Quero ter o histórico de todos os meus clientes em um só lugar.',
    value: 'Organização de contatos',
    id: 'profile-form-need-check-organization'
  },
  {
    icon: '🔔',
    title: 'Lembretes de tarefas',
    text: 'Quero organizar minha agenda de compromissos com os clientes',
    value: 'Lembretes de tarefas',
    id: 'profile-form-need-check-tasks'
  },
  {
    icon: '📨',
    title: 'Atendimento ao cliente',
    text: 'Quero agilizar o atendimento do time por e-mail, telefone e WhatsApp.',
    value: 'Atendimento ao cliente',
    id: 'profile-form-need-check-customer'
  },
  {
    icon: '📈',
    title: 'Análise de resultados',
    text: 'Quero ter relatórios prontos sobre as vendas e desempenho do time.',
    value: 'Análise de resultados',
    id: 'profile-form-need-check-analysis'
  },
  {
    icon: '🔍',
    title: 'Gestão comercial',
    text: 'Quero ver o funil de vendas e o status de cada negócio em tempo real.',
    value: 'Gestão comercial',
    id: 'profile-form-need-check-management'
  },
  {
    icon: '⚙️',
    title: 'Processos automáticos',
    text: 'Quero reduzir ou eliminar trabalho manual repetitivo.',
    value: 'Processos automáticos',
    id: 'profile-form-need-check-automation'
  },
  {
    icon: '🤝',
    title: 'Oportunidades de negócio',
    text: 'Quero gerar leads mais qualificados e propostas mais personalizadas.',
    value: 'Oportunidades de negócio',
    id: 'profile-form-need-check-opportunities'
  }
];

function NeedStep({ advanceStep, returnStep, stepPosition, totalSteps }) {
  const { user } = useAuth();
  const tracker = useTracking();

  useEffect(() => {
    tracker.trackProfileStepViewed({ user, step: stepPosition });
  }, []);

  const defaultValues = useMemo(() => ({
    user: {
      need: {
        checks: user.need?.checks ?? [],
        other: user.need?.other ?? ''
      }
    }
  }), [user]);

  const { mutate, isLoading } = useUpdateProfile();
  const handleNext = (values) => {
    mutate({ params: values }, {
      onSuccess: ({ data }) => {
        /**
         * Aqui é usada a resposta da requisição (`data`) ao invés do objeto de
         * usuário (`user`) porque no momento que essa função é chamada, o `user`
         * ainda não está atualizado com os dados que foram preenchidos no formulário.
         */
        tracker.trackProfileStepCompleted({ user: data });

        advanceStep();
      }
    });
  };

  const shuffledOptions = useMemo(() => shuffle(options), []);

  const isFirstStep = stepPosition === 1;

  return (
    <HookForm
      onSubmit={handleNext}
      name='profile-form'
      validationSchema={schema}
      defaultValues={defaultValues}
      className='mt-4'
      shouldUnregister
    >
      {() => (
        <>
          <Badge variant='light' className='text-small mb-3'>
            Passo {stepPosition} de {totalSteps}
          </Badge>

          <h1 className='mb-3'>
            O que pretende melhorar/resolver com o uso do Agendor?
          </h1>

          <HookFormField
            as={ToggleButtonGroup}
            name='user.need.checks'
            className='row gx-3 gy-6'
            type='checkbox'
          >
            {shuffledOptions.map((option, index) => (
              <NeedInputCard
                key={index}
                option={option}
                value={option.value}
              />
            ))}

            <OtherInputCard value='Outros' />
          </HookFormField>
          <HookFormFeedback name='user.need.checks' />

          <FooterButtons
            handlePrevious={!isFirstStep && returnStep}
            disabled={isLoading}
          />
        </>
      )}
    </HookForm >
  );
}

NeedStep.propTypes = propTypes;
NeedStep.defaultProps = defaultProps;

export default NeedStep;
