import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@/components/Popover';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import { plansPath, settingsManageUpdatePlanPath } from '@/routes';

const propTypes = {
  user: PropTypes.object,
  showPopover: PropTypes.bool,
  children: PropTypes.node,
  placement: PropTypes.string,
  flip: PropTypes.bool
};

const defaultProps = {
  user: {},
  showPopover: true,
  children: null,
  placement: 'right',
  flip: false
};

const FeatureControlPopover = ({ user, showPopover, children, placement, flip }) => {
  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;
  const newBillingPro = user.account.newBilling && user.account.paid && !hasAccess;
  const notExpiredNewPro = newBillingPro && !user.account.expired;

  const noAccessUrl = notExpiredNewPro
    ? settingsManageUpdatePlanPath({ new_plan_code: 'performance_plan' })
    : plansPath();
  const noAccessTextButton = notExpiredNewPro ? 'Contratar agora' : 'Ver planos e preços';

  return (
    <Popover
      hide={!showPopover}
      placement={placement}
      flip={flip}
      showArrow
      content={() => (
        <div className='d-flex flex-column justify-content-center'>
          <h4 className='text-darker-gray mb-3'>
            Disponível no Plano Performance
          </h4>
          <hr className='mb-4 text-darker-gray' />
          <div className='text-dark-gray mb-2'>
            Assine o Plano Performance e tenha<br /> acesso às funcionalidades:
            <div className='d-flex text-nowrap mt-2'>
              <Icon name='check' size='sm' className='me-2' />
              Automações
            </div>
            <div className='d-flex text-nowrap'>
              <Icon name='check' size='sm' className='me-2' />
              Qualificação obrigatória
            </div>
            <div className='d-flex text-nowrap'>
              <Icon name='check' size='sm' className='me-2' />
              Telefone virtual inteligente
            </div>
            <div className='d-flex text-nowrap'>
              <Icon name='check' size='sm' className='me-2' />
              Modelos de proposta comerciais
            </div>
          </div>
          <Button
            href={noAccessUrl}
            variant='primary'
            className='mt-4 me-8'
          >
            <Icon name='rocket' className='me-2 text-white' />
            {noAccessTextButton}
          </Button>
        </div>
      )}
    >
      <span>
        {children}
      </span>
    </Popover>
  );
};

FeatureControlPopover.propTypes = propTypes;
FeatureControlPopover.defaultProps = defaultProps;

export default FeatureControlPopover;
