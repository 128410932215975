import React, { useState, useMemo, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import ProposalModelsModal from '@/components/Proposal/ProposalModelsModal';
import {
  useCreateProposalModel, useUpdateProposalModel,
  useProposalModel, proposalModelKeys
} from '@/api';
import {
  prepareProposalModelFrom, formatAddressLine,
  getScreenSizeLabel, downloadBlob
} from '@/utils';
import { useMediaQuery, useScreenSize } from '@/hooks';
import { compact } from 'lodash';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import ProposalModal from '@/components/Proposal/ProposalModal';
import ShareProposalModal from '@/components/Proposal/ShareProposalModal';
import CreateEmailModal from '@/components/Email/CreateEmailModal';
import APIErrorMessage from '@/components/APIErrorMessage';

DetailsProposal.defaultProps = {
  deal: null,
  account: null,
  relatedEntity: null,
  entity: null,
  entityType: '',
  onCreateProposal: () => { }
};

DetailsProposal.propTypes = {
  deal: PropTypes.object,
  account: PropTypes.object,
  relatedEntity: PropTypes.object,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onCreateProposal: PropTypes.func
};

const WAIT_DELAY = 2000;

const LIMITED_MODAL_SIZES = {
  sm: 'lg',
  md: 'xxl',
  lg: 'xxl',
  xl: 'xxxl',
  xxxl: 'xxxl'
};

function DetailsProposal({ deal, account, relatedEntity, entity, entityType, onCreateProposal }) {
  const queryClient = useQueryClient();
  const alert = useAlert();
  const { user } = useAuth();
  const tracker = useTracking();
  const [modalKey, setModalKey] = useState();
  const [proposalModelId, setProposalModelId] = useState();
  const [proposalModel, setProposalModel] = useState();
  const [isLoadingProposalImages, setIsLoadingProposalImages] = useState(false);
  const [showProposalFormModal, setShowProposalFormModal] = useState(false);
  const [showProposalModelsModal, setShowProposalModelsModal] = useState(false);
  const [showShareProposalModal, setShowShareProposalModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [shareProposalEmail, setShareProposalEmail] = useState(null);
  const [proposalPdfUrl, setProposalPdfUrl] = useState('');

  const [isDisabledCreate, setIsDisabledCreate] = useState(false);

  /*
   * Evento disparado para bloquear a criação/duplicação/cópia de modelos
   * Após atingir o limite de modelos do plano.
   */
  const handleDisabledCreateChange = (newDisabledCreateValue) => {
    setIsDisabledCreate(newDisabledCreateValue);
  };

  const screenSize = useScreenSize();
  const modalSize = getScreenSizeLabel(screenSize);
  const isBelow1200 = useMediaQuery('(max-width: 1200px)');

  const defaultProposalModelValues = {
    title: 'PROPOSTA',
    proposalModelSections: [
      { title: 'Introdução e apresentação', sectionType: 'rich_text', content: '', position: 0 },
      { title: 'Produtos e serviços', sectionType: 'products', content: '', position: 1 },
      { title: 'Detalhes da proposta', sectionType: 'rich_text', content: '', position: 2 }
    ],
    banner: null,
    logo: null,
    legalName: account?.legalName,
    cpfCnpj: account?.cpfCnpj,
    address: formatAddressLine(account?.address),
    hiddenFields: {
      version: false,
      cpfCnpj: false,
      address: false,
      expiredAt: false,
      senderSection: false,
      recipientSection: false,
      recipientOrganizationSection: false,
      productPrice: false,
      productQuantity: false,
      productDiscount: false,
      productTotal: false,
      productTotalWithDiscount: false,
      productSummaryTotal: false,
      productSummaryDiscount: false,
      productSummaryTotalWithDiscount: false,
      footerSection: false,
      footerLogo: false
    }
  };

  const { data: proposalModelData, isFetching: isLoadingProposalModel } = useProposalModel({
    id: proposalModelId,
    config: { enabled: Boolean(showProposalFormModal && proposalModelId) }
  });

  const createProposalModelMutation = useCreateProposalModel();
  const updateProposalModelMutation = useUpdateProposalModel();
  const handleOpenEmailModal = () => {
    setShowEmailModal(true);
  };

  const currentProposalModel = useMemo(() => {
    const result = proposalModelData?.data ?? defaultProposalModelValues;
    return result;
  }, [proposalModelData]);

  async function prepareProposal(proposalData, isBlank = false, abortOpts = {}) {
    if (isBlank) {
      setModalKey(Math.random());
      setProposalModel(proposalData);
      return;
    }

    setIsLoadingProposalImages(true);
    try {
      let proposalModelSections = proposalData.proposalModelSections;

      const files = {
        banner: await downloadBlob(proposalData.banner?.url, abortOpts),
        logo: await downloadBlob(proposalData.logo?.url, abortOpts)
      };

      if (!proposalModelSections?.length) {
        proposalModelSections = defaultProposalModelValues.proposalModelSections;
      }

      const output = {
        ...proposalData,
        banner: files?.banner && {
          ...proposalData.banner,
          preview: URL.createObjectURL(files.banner),
          blob: files.banner
        },
        logo: files?.logo && {
          ...proposalData.logo,
          preview: URL.createObjectURL(files.logo),
          blob: files.logo
        },
        proposalModelSections
      };

      setModalKey(proposalData?.id);
      setIsLoadingProposalImages(false);
      setProposalModel(output);
    } catch (err) {
      setIsLoadingProposalImages(false);
    }
  }

  /*
   * A lib de preparar pdf sofre problemas com cors,
   * foi necessário forçar download para carregamento em blob
   */
  useEffect(() => {
    let isMounted = true;
    const abortCtrl = new AbortController();
    const abortOpts = { signal: abortCtrl.signal };

    if (isMounted) {
      if (showProposalFormModal && !isLoadingProposalModel) {
        if (currentProposalModel?.id) {
          prepareProposal(currentProposalModel, false, abortOpts);
        } else {
          prepareProposal(defaultProposalModelValues, true, abortOpts);
        }
      }
    }

    return () => {
      isMounted = false;
      abortCtrl?.abort();
    };
  }, [currentProposalModel, showProposalFormModal, isLoadingProposalModel]);

  const loading = isLoadingProposalModel || isLoadingProposalImages;

  const handleCreateProposalModel = (data, done) => {
    createProposalModelMutation.mutate(data, {
      onSuccess: () => {
        alert.show(
          data?.copy
            ? 'O modelo foi duplicado com sucesso!'
            : 'Modelo criado com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setTimeout(done, WAIT_DELAY);
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='proposal_models' action='create' />,
          { variant: 'danger', timeout: 10000 }
        );
      }
    });
  };

  const handleUpdateProposalModel = (data, done) => {
    updateProposalModelMutation.mutate({ id: data.id, params: data }, {
      onSuccess: () => {
        alert.show(
          'Modelo atualizado com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
        setTimeout(done, WAIT_DELAY);
      },
      onError: (err) => {
        done?.(err);
      }
    });
  };

  const handleUpsertProposalModel = (upsertData, done) => {
    if (isEqual(upsertData, proposalModel)) {
      return;
    }

    const data = prepareProposalModelFrom(upsertData);
    // Remove logo e banner não modificados para evitar uploads desnecessários
    if (isEqual(data?.logo, proposalModel?.logo)) {
      delete data?.logo;
    }

    if (isEqual(data?.banner, proposalModel?.banner)) {
      delete data?.banner;
    }

    if (data?.id) {
      handleUpdateProposalModel(data, done);
      return true;
    }

    handleCreateProposalModel(data, done);
    return true;
  };

  const handleSaveNewProposalModel = (data, done) => {
    handleUpsertProposalModel(data, async (err) => {
      if (!err) {
        await queryClient.invalidateQueries(proposalModelKeys.all);
      }
      done?.(err);
    });
  };

  const handleRenameProposalModel = (data, done) => {
    handleUpdateProposalModel(data, async (err) => {
      if (!err) {
        await queryClient.invalidateQueries(proposalModelKeys.all);
      }
      done?.(err);
    });
  };

  const handleHideProposal = (done) => {
    setShowProposalModelsModal(true);
    setShowProposalFormModal(false);
    setProposalModelId(null);
    done?.();
  };

  const handleCloseEmailModal = () => {
    setShowEmailModal(false);
    setShowShareProposalModal(false);
  };

  const handleOpenProposal = async (proposalData, done) => {
    setShowProposalFormModal(true);
    setProposalModelId(null);
    await prepareProposal(proposalData, false);
    done?.();
  };

  const handleOpenProposalModel = (id) => {
    setShowProposalFormModal(true);
    setProposalModelId(id);
    setProposalModel(null);
  };

  const handleNewProposalModel = () => {
    setShowProposalFormModal(true);
    setProposalModelId(null);
    setProposalModel(null);
  };

  const handleEditProposalModel = (id) => {
    setShowProposalFormModal(true);
    setProposalModelId(id);
    setProposalModel(null);
  };

  const handleGenerateProposal = (activity, done) => {
    onCreateProposal(activity, (_error, savedActivity) => {
      setTimeout(() => {
        setShowProposalFormModal(false);
        setShowProposalModelsModal(false);
        setShareProposalEmail({ attachments: activity?.documents, to: entity?.contact?.email });
        setProposalModelId(null);
        setProposalModel(null);
        setShowShareProposalModal(true);
        setProposalPdfUrl(savedActivity?.documents?.[0]?.url);
        done?.();
      }, WAIT_DELAY);
    });
  };

  const handleOpenProposalModels = async () => {
    await queryClient.invalidateQueries(proposalModelKeys.all);
    setShowProposalModelsModal(true);
    setShowProposalFormModal(false);
    setProposalModelId(null);

    tracker.trackActionButtonClicked({ user, action: 'Criar proposta' });
  };

  const handleHideProposalModel = () => {
    setShowProposalModelsModal(false);
    setShareProposalEmail(null);
    setShowEmailModal(false);
    setProposalPdfUrl('');
    setShowShareProposalModal(false);
  };

  return (
    <>
      <Button
        id='proposal-modal'
        className={
          `mt-2 d-flex align-items-center text-nowrap w-100
          ${isBelow1200 ? 'ms-0' : 'me-8'}`
        }
        onClick={handleOpenProposalModels}
        aria-label='Gerar proposta'
      >
        <Icon name='proposal' className='me-2' size='fixed' />
        <span>Gerar proposta</span>
      </Button>

      <ProposalModelsModal
        show={showProposalModelsModal}
        modalSize={LIMITED_MODAL_SIZES[modalSize]}
        deal={deal}
        relatedEntity={relatedEntity}
        onSaveNewProposalModel={handleSaveNewProposalModel}
        onRenameProposalModel={handleRenameProposalModel}
        onOpenProposal={handleOpenProposal}
        onOpenProposalModel={handleOpenProposalModel}
        onNewProposalModel={handleNewProposalModel}
        onEditProposalModel={handleEditProposalModel}
        onHide={handleHideProposalModel}
        onDisabledCreateChange={handleDisabledCreateChange}
      >
        <ProposalModal
          key={modalKey}
          deal={deal}
          proposalModel={proposalModel}
          account={account}
          show={showProposalFormModal}
          loading={loading}
          entity={entity}
          entityType={entityType}
          modalSize={LIMITED_MODAL_SIZES[modalSize]}
          onHide={handleHideProposal}
          onUpsertProposalModel={handleUpsertProposalModel}
          onCreateActivityProposal={handleGenerateProposal}
          isDisabledCreate={isDisabledCreate}
        />
      </ProposalModelsModal>

      {
        showEmailModal
          ? (
            <CreateEmailModal
              show={showEmailModal}
              onClose={handleCloseEmailModal}
              attachments={shareProposalEmail?.attachments}
              to={compact([shareProposalEmail?.to])}
              entityId={deal?.id}
              entityType={'deal'}
            />

          )
          : null
      }
      <ShareProposalModal
        url={proposalPdfUrl}
        show={showShareProposalModal}
        onOpenEmailModal={handleOpenEmailModal}
        onClose={handleHideProposalModel}
      />
    </>
  );
}

export default DetailsProposal;
