import React from 'react';
import PropTypes from 'prop-types';
import Main from '@/components/Main';

const propTypes = {
  children: PropTypes.node
};

function MainBody({ children }) {
  return (
    <Main
      fluid
      flex
      overflow={false}
      className='pt-8 px-4 overflow-hidden position-relative'
    >
      {children}
    </Main>

  );
}

MainBody.propTypes = propTypes;
export default MainBody;
