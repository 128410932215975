import React from 'react';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import { ACTIVITY_TYPES } from '@/utils';

const ActivitiesTypesDropdown = ({ onSelect, dropdownClassNames, tabs }) => {
  const handleSelect = (type) => {
    onSelect(type);
  };

  const CollapsedNavTabs = () => (
    tabs.map((tab) => (
      <Dropdown.Item
        as='button'
        onClick={() => handleSelect(tab)}
        className={dropdownClassNames}
        key={tab}
      >
        <Icon name={tab} className='me-2' />
        {tab === ACTIVITY_TYPES.MEETING ? 'Reunião' : 'Visita'}
      </Dropdown.Item>
    ))
  );

  return (
    <Dropdown>
      <Dropdown.Toggle
        id='menu-toggle-activity-type'
        variant='link'
        className={dropdownClassNames}
      >
        <Icon name='plus' size='sm' />
        <span className='h5'>Mais</span>
      </Dropdown.Toggle>
      <Dropdown.Menu renderOnMount>
        <CollapsedNavTabs />
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ActivitiesTypesDropdown;
