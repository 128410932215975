import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  InputPreview,
  PrivacyFieldPreview,
  CustomFieldsSectionPreview
} from '@/components/CustomFields';
import Main from '@/components/Main';
import { useAuth } from '@/lib/auth';
import { useDocumentTitle } from '@/hooks';
import NotFound from '@/pages/not-found';
import { Select, TextArea, TextInput } from '@/components/Inputs';
import CreateCustomFields from '@/components/CreateCustomFields';
import {
  useCreateDealsCustomField,
  useUpdateDealsCustomField,
  useDeleteDealsCustomField,
  useDealsCustomFields
} from '@/api';
import DealsReorderFields from '@/components/settings/DealsReorderFields';

const PAGE_TITLE = 'Campos customizados';
function DealsFormPreview() {
  const { user } = useAuth();
  const createDealsCustomFieldMutation = useCreateDealsCustomField();
  const updateDealsCustomFieldMutation = useUpdateDealsCustomField();
  const deleteDealsCustomFieldMutation = useDeleteDealsCustomField();
  const { data: customFields, error, isSuccess } = useDealsCustomFields();

  useDocumentTitle(PAGE_TITLE);

  if (!user.account.paid || error) {
    return <NotFound />;
  }

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid={false} >
      <Container>
        <h1>
          Formulário de negócio
        </h1>

        <p className='subtitle text-dark-gray'>
          Personalize os formulários do Agendor de acordo com as suas
          necessidades específicas.
        </p>

        <hr className='my-4' />

        <h2 className='my-4'>
          Dados básicos
        </h2>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Nome do negócio'
              variant='light'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Empresa/Pessoa'
              variant='light'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Responsável'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Valor total'
              variant='light'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Funil'
            />
          </Col>
          <Col sm={3}>
            <InputPreview
              as={TextInput}
              label='Data de início'
              variant='light'
            />
          </Col>

          <Col sm={3}>
            <InputPreview
              as={TextInput}
              label='Data de conclusão'
              variant='light'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={12}>
            <InputPreview
              as={TextArea}
              label='Descrição'
              variant='light'
            />
          </Col>
        </Row>

        <CustomFieldsSectionPreview
          section='basic_data'
          entity='deal'
          fields={customFields.data}
          updateMutation={updateDealsCustomFieldMutation}
          deleteMutation={deleteDealsCustomFieldMutation}
        />

        <div className='d-flex mb-4'>
          <CreateCustomFields
            section='basic_data'
            mutation={createDealsCustomFieldMutation}
            entity='deal'
            className='me-2'
          />

          <DealsReorderFields
            section='basic_data'
            customFields={customFields.data}
            sectionTitle='Dados básicos'
          />
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Produtos e serviços
          </h2>

          <p className='subtitle text-dark-gray'>
            Quais esta empresa tem potencial de compra?
          </p>

          <InputPreview
            as={Select}
            label=''
            placeholder='Buscar...'
          />
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Privacidade
          </h2>

          <p className='subtitle text-dark-gray'>
            Quem pode ver o histórico e editar esse negócio?
          </p>

          <div className='mb-2'>
            <PrivacyFieldPreview />
          </div>
        </div>
      </Container>
    </Main>
  );
}

export default DealsFormPreview;
