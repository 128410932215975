import React from 'react';
import PropTypes from 'prop-types';
import Popover from '@/components/Popover';
import Button from '@/components/Button';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';

ProposalDeleteSection.propTypes = {
  section: PropTypes.object,
  onDeleteSection: PropTypes.func,
  disabled: PropTypes.bool
};

ProposalDeleteSection.defaultProps = {
  section: {},
  onDeleteSection: () => {},
  disabled: false
};

function ProposalDeleteSection({ section, disabled, onDeleteSection }) {
  return (
    <div className='d-flex justify-content-end align-content-end flex-grow-1'>
      {disabled
        ? (
          <Tooltip content='É necessário ter pelo menos uma seção'>
            <div>
              <Button
                className='text-dark-gray'
                variant='link'
                disabled
              >
                <Icon name='delete' size='sm' />
              </Button>
            </div>
          </Tooltip>
        )
        : (
          <Popover
            rootCloseEvent='mousedown'
            placement='bottom-end'
            showArrow
            contentClassName='pt-4 pb-4 px-3'
            content={({ onHide }) => (
              <>
                <h4 className='ms-3 me-3 mb-3 text-darker-gray fw-bold'>
                  Deseja realmente excluir esta seção?
                </h4>
                <Button
                  className='ms-3 me-3'
                  variant='light'
                  onClick={(event) => {
                    onHide();
                    event.stopPropagation();
                  }}
                >
                  Agora não
                </Button>
                <Button
                  className='me-3'
                  variant='danger'
                  type='submit'
                  onClick={() => onDeleteSection({ ...section, hidden: true, _destroy: 1 })}
                >
                  Sim, excluir
                </Button>
              </>
            )}
          >
            <div>
              <Tooltip content='Excluir seção'>
                <Button className='text-dark-gray ms-8' variant='link'>
                  <Icon name='delete' size='sm' />
                </Button>
              </Tooltip>
            </div>
          </Popover>
        )}
    </div>
  );
}

export default ProposalDeleteSection;
