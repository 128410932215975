import React, { useState, useEffect } from 'react';
import { useAlert } from 'react-alert';
import PropTypes from 'prop-types';
import exportImage from 'images/export.svg';
import successImage from 'images/success.svg';
import resultImage from 'images/export-failed-result.svg';
import fullPageGif from 'images/full-page-loading.gif';
import Button from '@/components/Button';
import ExternalLink from '@/components/ExternalLink';
import Form from '@/components/Form';
import FormField from '@/components/FormField';
import Icon from '@/components/Icon';
import { RadioButton } from '@/components/Inputs';
import Modal from '@/components/Modal';
import Tooltip from '@/components/Tooltip';
import Stepper from '@/components/Stepper';
import Alert from '@/components/Alert';
import { useCreateExport, useExport } from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';

const RESOURCES = {
  organization: 'companies',
  person: 'people',
  deal: 'deals',
  product: 'products',
  activity: 'activities'
};

const OPTIONS = {
  'organization': [
    {
      label: 'Empresas',
      values: {
        include_products: false
      }
    },
    {
      label: 'Empresas + Produtos',
      values: {
        include_products: true
      }
    }
  ],
  'person': [
    {
      label: 'Pessoas',
      values: {
        include_org: false,
        include_products: false
      }
    },
    {
      label: 'Pessoas + detalhes de Empresas',
      values: {
        include_org: true,
        include_products: false
      }
    },
    {
      label: 'Pessoas + detalhes de Empresas + Produtos',
      values: {
        include_org: true,
        include_products: true
      }
    }
  ],
  'deal': [
    {
      label: 'Negócios',
      values: {
        include_org_person: false,
        include_products: false
      }
    },
    {
      label: 'Negócios + detalhes de Empresas e Pessoas',
      values: {
        include_org_person: true,
        include_products: false
      }
    },
    {
      label: 'Negócios + detalhes de Empresas e Pessoas + Produtos',
      tooltip: 'Produtos separados por vírgula, na mesma linha.',
      values: {
        include_org_person: true,
        include_products: true
      }
    },
    {
      label: 'Negócios + detalhes de Produtos',
      tooltip: 'Um produto por linha, com preço, quantidade, desconto, categoria.',
      values: {
        include_org_person: false,
        include_products: false,
        include_full_products: true
      }
    }
  ]
};

const propTypes = {
  exportEntity: PropTypes.string.isRequired,
  showExportModal: PropTypes.bool,
  onHide: PropTypes.func,
  initialSize: PropTypes.oneOf(['sm', 'md']),
  withoutOptions: PropTypes.bool
};

const defaultProps = {
  showExportModal: false,
  filters: {},
  initialSize: 'md',
  withoutOptions: false
};

const defaultValues = { options_index: '0' };

function ExportOptionStep(props) {
  const {
    onHide, exportEntity, advanceStep, onSuccess, filters, alertMessage, withoutOptions
  } = props;
  const mutation = useCreateExport();
  const alert = useAlert();

  const resource = RESOURCES[exportEntity];
  const options = OPTIONS[exportEntity];

  const createExport = (values = {}) => {
    const params = { resource, options: { ...values, ...filters } };

    mutation.mutate(params, {
      onSuccess: ({ data }) => {
        onSuccess(data);
        advanceStep();
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='export' action='create' />,
          { variant: 'danger', timeout: 5000 }
        );
      }
    });
  };

  useEffect(() => {
    if (withoutOptions) {
      createExport();
    }
  }, []);

  if (withoutOptions) {
    return (
      <div className='text-center'>
        <img className='width-3 height-3 my-6' src={fullPageGif} />
      </div>
    );
  }

  return (
    <Form
      defaultValues={defaultValues}
      onSubmit={({ options_index: index }) => createExport(options[index]?.values)}
    >
      {
        () => (
          <>
            <div className='d-flex flex-column align-items-center text-center'>
              <img src={exportImage}/>

              <h2>Quais informações deseja visualizar na exportação?</h2>
            </div>

            <div className='my-4'>
              {
                options.map(({ label, tooltip }, index) => (
                  <div key={`option-container-${index}`} className='d-flex align-items-center'>
                    <FormField
                      as={RadioButton}
                      id={`export_options_${index}`}
                      key={`export_options_${index}`}
                      radioValue={index.toString()}
                      name='options_index'
                      label={<div className='ms-2'>{label}</div>}
                    />
                    <div>
                      { exportEntity === 'deal' && tooltip
                        ? (
                          <Tooltip content={tooltip}>
                            <Button as='label' variant='link' className='p-0'>
                              <Icon size ='sm'
                                className='ms-1 mb-4 text-dark-gray'
                                name='help-circle'
                              />
                            </Button>
                          </Tooltip>
                        )
                        : null }
                    </div>
                  </div>
                ))
              }
            </div>

            {
              alertMessage &&
              <div className='d-flex my-2'>
                <Alert variant='light' className='text-dark-gray flex-fill'>
                  {alertMessage}
                </Alert>
              </div>
            }

            <div className='d-flex'>
              <Button
                variant='outline-dark-gray'
                className='flex-fill me-2'
                onClick={onHide}
              >
                Cancelar
              </Button>

              <Button
                className='flex-fill'
                type='submit'
              >
                Exportar
              </Button>
            </div>
          </>
        )
      }
    </Form>
  );
}

const REFETCH_INTERVAL = 5000;

function LoadingStep(props) {
  const { advanceStep, exportId, onSuccess } = props;

  useExport({
    exportId,
    config: {
      refetchInterval: REFETCH_INTERVAL,
      cacheTime: 0,
      onSuccess: ({ data }) => {
        if (data.status === 'finished') {
          onSuccess(data);
          advanceStep();
        } else if (data.status === 'failed') {
          advanceStep();
        }
      }
    }
  });

  return (
    <div className='text-center'>
      <img className='width-3 height-3 my-6' src={fullPageGif} />
      <h2 className='mb-3 mx-1'>Estamos realizando a sua exportação!</h2>
      <p className='text-dark-gray m-1'>
        Não feche esta mensagem. Em alguns instantes, sua exportação estará pronta.
      </p>
    </div>
  );
}

function DownloadStep({ downloadUrl }) {
  if (!downloadUrl) {
    return (
      <div className='text-center'>
        <img className='my-6' src={resultImage} />
        <h2 className='mb-3 mx-1'>Falha na exportação</h2>
        <p className='text-dark-gray m-1'>
          Aguarde alguns minutos e tente novamente. Se o erro persistir,
          entre em contato com o nosso suporte
        </p>
      </div>
    );
  }

  return (
    <div className='text-center'>
      <img className='width-3 height-3 my-6' src={successImage}/>
      <h2 className='mb-6'>Sua exportação está pronta!</h2>

      <Button
        as={ExternalLink}
        target={'_blank'}
        href={downloadUrl}
        className='d-flex'
      >
        <Icon name='download' className='me-2'/>
        <h5 className='mb-0'>Baixar arquivo</h5>
      </Button>
    </div>
  );
}

function CreateExportModal(props) {
  const {
    exportEntity, showExportModal, onHide, filters, alertMessage, initialSize, withoutOptions
  } = props;
  const [modalSize, setModalSize] = useState(initialSize);
  const [accountExport, setAccountExport] = useState();

  const handleHide = () => {
    onHide();
    setAccountExport();
  };

  const onExportOptionSuccess = (data) => {
    setModalSize('sm');
    setAccountExport(data);
  };

  return (
    <Modal
      size={modalSize}
      show={showExportModal}
      onHide={handleHide}
      /*
       * Foi utilizada a propriedade onShow para que o modal volte ao tamanho inicial
       * antes de sua exibição
       */
      onShow={() => setModalSize(initialSize)}
      backdrop='static'
      bodyClassName='p-8'
    >
      <Stepper>
        <ExportOptionStep
          onHide={onHide}
          exportEntity={exportEntity}
          onSuccess={onExportOptionSuccess}
          filters={filters}
          alertMessage={alertMessage}
          withoutOptions={withoutOptions}
        />
        <LoadingStep
          exportId={accountExport?.id}
          onSuccess={(data) => setAccountExport(data)}
        />
        <DownloadStep
          downloadUrl={accountExport?.downloadUrl}
        />
      </Stepper>

    </Modal>
  );
}

CreateExportModal.propTypes = propTypes;
CreateExportModal.defaultProps = defaultProps;

export default CreateExportModal;
