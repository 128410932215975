import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { useCurrentAccount } from '@/api';
import { Col, Row } from 'react-bootstrap';
import DetailsEmail from '@/components/EntityModal/DetailsEmail';
import DetailsProposal from '@/components/EntityModal/Deal/DetailsProposal';
import DetailsCard from '@/components/EntityModal/DetailsCard';
import DetailsWhatsApp from '@/feature/whatsapp/DetailsWhatsApp';
import Skeleton from '@/components/Skeleton';
import { useMediaQuery } from '@/hooks';
import VoipPopover from '@/components/Voip/CallPopover/Popover';

DetailsIntegrations.defaultProps = {
  deal: {},
  voip: {},
  loading: true,
  entity: {},
  entityType: '',
  onCreateProposal: () => { },
  onRefreshModal: () => { },
  onUpdateEntity: () => { }
};

DetailsIntegrations.propTypes = {
  deal: PropTypes.object,
  voip: PropTypes.object,
  loading: PropTypes.bool,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onCreateProposal: PropTypes.func,
  onRefreshModal: PropTypes.func,
  onUpdateEntity: PropTypes.func
};

function DetailsIntegrations({
  deal,
  loading,
  entity,
  entityType,
  onCreateProposal,
  onUpdateEntity
}) {
  const { data: accountData, isLoading: isLoadingAccount } = useCurrentAccount();
  const account = useMemo(() => accountData?.data, [accountData]);

  const isBelow1200 = useMediaQuery('(max-width: 1200px)');
  /* eslint-disable no-magic-numbers */
  const COL_SIZE = isBelow1200 ? 12 : 6;
  /* eslint-enable no-magic-numbers */

  const onUpdatePerson = (values, done, personData) => {
    onUpdateEntity(values, done, { ...personData, entityType: 'person' });
  };

  const onUpdateOrganization = (values, done, organizationData) => {
    onUpdateEntity(values, done, { ...organizationData, entityType: 'organization' });
  };

  const decoratedDeal = getDecoratedDeal(deal, entity);
  const relatedEntity = decoratedDeal?.organization || decoratedDeal?.person;

  if (loading || isLoadingAccount) {
    return <Skeleton width='100%' height={200} className='mb-3' />;
  }

  return (
    <DetailsCard title='Ações' className='mb-3' actionTitle>
      <Row className={`justify-content-center overflow-hidden ${isBelow1200 ? 'flex-column' : ''}`}>
        <Col sm={COL_SIZE} className={
          `d-flex flex-column align-items-center
          ${isBelow1200 ? 'mb-2' : 'me-n6'}`
        }>
          <DetailsEmail entityId={deal.id} entityType='deal' showText />
          <DetailsProposal
            deal={deal}
            account={account}
            entity={entity}
            entityType={entityType}
            relatedEntity={relatedEntity}
            onCreateProposal={onCreateProposal}
          />
        </Col>
        <Col sm={COL_SIZE} className={
          `d-flex flex-column align-items-center
          ${isBelow1200 ? '' : 'me-n6'}`
        }>
          <VoipPopover
            entity={decoratedDeal}
            entityType='deal'
            onUpdatePerson={onUpdatePerson}
            onUpdateOrganization={onUpdateOrganization}
          />
          <DetailsWhatsApp
            entity={decoratedDeal}
            relatedEntity={relatedEntity}
            entityType='deal'
            onUpdatePerson={onUpdatePerson}
            onUpdateOrganization={onUpdateOrganization}
            hasMarginTop
          />
        </Col>
      </Row>
    </DetailsCard>
  );
}

function getDecoratedDeal(deal, relatedEntity) {
  if (deal.person) {
    return { ...deal, person: relatedEntity, relatedEntityType: 'person' };
  } else if (deal.organization) {
    return { ...deal, organization: relatedEntity, relatedEntityType: 'organization' };
  } else {
    return deal;
  }
}

export default DetailsIntegrations;
