import { useQuery, useMutation, useQueryClient } from 'react-query';
import { get, post, patch, _delete } from '@/lib/fetch';
import { accessoryCallCache } from '@/utils';

export const dealsCustomFieldsKeys = {
  all: ['deals-custom-fields']
};

export async function getDealsCustomFields() {
  const { data } = await get('/deals/custom_fields');

  return data;
}

export function useDealsCustomFields({ config = {} } = {}) {
  return useQuery({
    ...accessoryCallCache,
    ...config,
    queryKey: dealsCustomFieldsKeys.all,
    queryFn: () => getDealsCustomFields()
  });
}

export async function createDealsCustomField(params = {}) {
  const { data } = await post('/deals/custom_fields', params);

  return data;
}

export function useCreateDealsCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(dealsCustomFieldsKeys.all);
  };

  return useMutation(
    createDealsCustomField,
    {
      onSuccess,
      ...config
    }
  );
}

export async function updateDealsCustomField({ customFieldId, params = {} }) {
  const { data } = await patch(`/deals/custom_fields/${customFieldId}`, params);

  return data;
}

export function useUpdateDealsCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(dealsCustomFieldsKeys.all);
  };

  return useMutation(
    updateDealsCustomField,
    {
      onSuccess,
      ...config
    }
  );
}

export async function updateDealsCustomFieldOrder({ params = {} }) {
  const { data } = await patch('/deals/custom_fields/batch', params);

  return data;
}

export function useUpdateDealsCustomFieldOrder({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(dealsCustomFieldsKeys.all);
  };

  return useMutation(
    updateDealsCustomFieldOrder,
    {
      onSuccess,
      ...config
    }
  );
}

export async function deleteDealsCustomField({ customFieldId }) {
  const { data } = await _delete(`/deals/custom_fields/${customFieldId}`);

  return data;
}

export function useDeleteDealsCustomField({ config = {} } = {}) {
  const queryClient = useQueryClient();

  const onSuccess = async () => {
    await queryClient.invalidateQueries(dealsCustomFieldsKeys.all);
  };

  return useMutation(
    deleteDealsCustomField,
    {
      onSuccess,
      ...config
    }
  );
}
