import React from 'react';
import PropTypes from 'prop-types';
import { canHideFields } from '@/policies/proposalmodels';
import Tooltip from '@/components/Tooltip';
import Icon from '@/components/Icon';
import Authorization from '@/components/Authorization';
import strings from '@/strings';

ToggleFieldVisibility.propTypes = {
  hiddenFields: PropTypes.object,
  field: PropTypes.string,
  onChangeFieldVisibility: PropTypes.func,
  hideSecundaryElement: PropTypes.bool
};

ToggleFieldVisibility.defaultProps = {
  hiddenFields: {},
  field: '',
  onChangeFieldVisibility: () => {},
  hideSecundaryElement: false
};

function ToggleFieldVisibility(props) {
  const {
    hiddenFields,
    field,
    onChangeFieldVisibility,
    hideSecundaryElement
  } = props;

  const fieldLabel = strings.labels.proposal_models.field_label;
  const hiddenField = hiddenFields?.[field];

  const toggleFieldVisibility = () => {
    const newValue = !hiddenField;
    onChangeFieldVisibility(field, newValue);
  };

  return (
    <Tooltip
      placement='top'
      content={
        hiddenField
          ? `Exibir ${fieldLabel[field] ?? 'coluna'}`
          : `Ocultar ${fieldLabel[field] ?? 'coluna'}`
      }
    >
      <div className='readable-component-button' hidden={hideSecundaryElement}>
        <Authorization policy={canHideFields}>
          <Icon
            name={hiddenField ? 'hidden' : 'visible'}
            className={'cursor-pointer text-primary my-n1 p-0 me-n5'}
            onClick={() => toggleFieldVisibility(field)}
            /*
             * O ícone tamanho md (20x20) está um pouco maior que o esperado
             * e acaba interferindo na visualização dos outros ícones
             */
            style={{ height: '19' }}
          />
        </Authorization>
      </div>
    </Tooltip>
  );
}

export default ToggleFieldVisibility;
