import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PROPOSAL_SECTIONS_TYPE } from '@/utils';
import Button from '@/components/Button';
import Popover from '@/components/Popover';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';

ProposalAddSection.propTypes = {
  hasProducts: PropTypes.bool,
  onAddSection: PropTypes.func
};

ProposalAddSection.defaultProps = {
  hasProducts: false,
  onAddSection: () => { }
};

function ProposalAddSection({ hasProducts, onAddSection }) {
  const [isPopoverVisible, setIsPopoverVisible] = useState(false);

  const handleButtonClick = () => {
    setIsPopoverVisible(true);
  };

  return (
    <div className='height-2 p-2 m-2'>
      <div className='d-flex flex-column'>
        <Popover
          placement='top'
          showArrow
          visible={isPopoverVisible}
          content={() => (
            <div>
              <div>
                <Button
                  size='sm'
                  className='w-100 justify-content-start'
                  variant='transparent-light'
                  onClick={() => onAddSection(PROPOSAL_SECTIONS_TYPE.RICH_TEXT)}
                >
                  <Icon name='types-text' className='text-dark-gray ms-n2 p-1' size='lg' />
                  <span className='text-dark'>Texto, imagem e tabela</span>
                </Button>
                <hr className='text-light-gray opacity-50' />
              </div>
              <div>
                <Tooltip
                  content='Para adicionar uma nova seção de produtos, exclua seção existente.'
                  placement='right'
                  className={hasProducts ? '' : 'd-none'}
                >
                  <div className='d-flex'>
                    <Button
                      variant='transparent-light'
                      className='w-100 justify-content-start'
                      disabled={hasProducts}
                      onClick={() => onAddSection(PROPOSAL_SECTIONS_TYPE.PRODUCTS)}
                    >
                      <Icon name='product' className='text-dark-gray me-2' />
                      <span className='text-dark'>Produtos e serviços</span>
                    </Button>
                  </div>
                </Tooltip>
              </div>
            </div>
          )}
        >
          <div>
            <div className='d-flex justify-content-between'>
              <hr className='mx-5 my-2 width-6 text-light-gray opacity-50' />
              <hr className='mx-5 my-2 width-6 text-light-gray opacity-50' />
            </div>
            <div className='d-flex justify-content-center mt-n3'>
              <Button
                variant='transparent-light'
                className='mt-n4'
                onClick={handleButtonClick}
              >
              + Adicionar seção
              </Button>
            </div>
          </div>
        </Popover>
      </div>

    </div>
  );
}

export default ProposalAddSection;
