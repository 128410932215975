import React, { useState } from 'react';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import Modal from '@/components/Modal';
import Icon from '@/components/Icon';
import { TextInput } from '@/components/Inputs';
import FormField from '@/components/FormField';
import Form from '@/components/Form';
import { getValidationErrors } from '@/utils';

const propTypes = {
  show: PropTypes.bool,
  edit: PropTypes.bool,
  onHide: PropTypes.func,
  onSubmit: PropTypes.func,
  proposalModel: PropTypes.object
};

const defaultProps = {
  show: false,
  edit: false,
  title: 'Renomear modelo',
  icon: 'edit-field',
  onHide: () => { },
  onSubmit: () => { },
  proposalModel: {}
};

const NAME_SIZE = 50;

function ProposalRenameModal({ show, edit, title, icon, onHide, proposalModel, onSubmit }) {
  const [submitting, setSubmitting] = useState(false);
  const schema = Yup.object().shape({
    name: Yup.string()
      .trim()
      .nullable()
      .max(NAME_SIZE, `O nome do modelo deve ter no máximo ${NAME_SIZE} caracteres.`)
      .required('O nome do modelo é obrigatório.')
  });

  const handleNameSubmit = (data, { setFieldError }) => {
    setSubmitting(true);
    onSubmit(data, (err) => {
      setSubmitting(false);
      if (err) {
        const errors = getValidationErrors(err, 'proposal_models');
        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });
      }
    });
  };

  const defaultValues = {
    ...proposalModel,
    name: edit ? proposalModel.name : ''
  };

  return (
    <Modal size='md' bodyClassName='p-8' show={show} onHide={onHide}>
      <Icon name={icon} size='lg' className='text-dark-gray mb-5' />
      <h2 className='text-darker-gray mb-3'>{title}</h2>
      <Form
        name='proposal-modal-rename'
        validationSchema={schema}
        defaultValues={defaultValues}
        onSubmit={handleNameSubmit}
      >
        {({ handleSubmit, handleBlur }) => (
          <>
            <FormField
              as={TextInput}
              maxLength={NAME_SIZE}
              name='name'
              className='mb-0'
              onBlur={handleBlur}
              placeholder='Nome do modelo'
              autoComplete='off'
              variant='light'
              label='Nome'
            />
            <div className='d-flex justify-content-end mt-6'>
              <Button
                disabled={submitting}
                variant='outline-dark-gray'
                className='me-4'
                onClick={onHide}
              >
                Cancelar
              </Button>
              <LoadingButton
                isLoading={submitting}
                variant='primary'
                onClick={handleSubmit}
              >
                Salvar
              </LoadingButton>
            </div>
          </>
        )}
      </Form>
    </Modal>
  );
}

ProposalRenameModal.propTypes = propTypes;
ProposalRenameModal.defaultProps = defaultProps;

export default ProposalRenameModal;
