import React from 'react';
import PropTypes from 'prop-types';
import Icon from '@/components/Icon';
import Select from '@/components/Inputs/Select';

const propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string
};

const defaultProps = {
  label: 'Tipo da atividade',
  placeholder: 'Ex.: Ligação'
};

const options = [
  {
    leftAdornment: <Icon name='email' size='sm' />,
    label: 'E-mail',
    value: 'email'
  },
  {
    leftAdornment: <Icon name='call' size='sm' />,
    label: 'Ligação',
    value: 'call'
  },
  {
    leftAdornment: <Icon name='whatsapp' size='sm' />,
    label: 'WhatsApp',
    value: 'whatsapp'
  },
  {
    leftAdornment: <Icon name='proposal' size='sm' />,
    label: 'Proposta',
    value: 'proposal'
  },
  {
    leftAdornment: <Icon name='meeting' size='sm' />,
    label: 'Reunião',
    value: 'meeting'
  },
  {
    leftAdornment: <Icon name='visit' size='sm' />,
    label: 'Visita',
    value: 'visit'
  },
  {
    leftAdornment: <Icon name='task' size='sm' />,
    label: 'Tarefa',
    value: 'task'
  }
];

function ActivityTypeSelect(props) {
  return (
    <Select
      { ...props }
      options={options}
    />
  );
}

ActivityTypeSelect.propTypes = propTypes;
ActivityTypeSelect.defaultProps = defaultProps;

export default ActivityTypeSelect;
