import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import * as Yup from 'yup';
import { useCreateImportMappings } from '@/api';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import Alert from '@/components/Alert';
import Form from '@/components/Form';
import ImportTable from '@/components/Imports/ImportTable';
import MappingTable from '@/components/Imports/MappingTable';

const ignoredColumnNames = {
  default: ['Motivo de erro durante a importação'],
  organizations: ['Rádio', 'Cadastrado por', 'Data de cadastro'],
  people: ['E-mail da Empresa', 'Cadastrado por', 'Data de cadastro'],
  deals: ['Origem do cliente', 'Cadastrado por', 'Data de cadastro'],
  activities: [],
  products: []
};

const propTypes = {
  advanceStep: PropTypes.func.isRequired,
  returnStep: PropTypes.func.isRequired,
  nextStepButtonRef: PropTypes.object.isRequired,
  previousStepButtonRef: PropTypes.object.isRequired,
  initialData: PropTypes.object,
  resource: PropTypes.string,
  file: PropTypes.object,
  onSubmit: PropTypes.func,
  onSuccess: PropTypes.func,
  getIgnoredColumnTarget: PropTypes.func
};

const defaultProps = {
  advanceStep: () => {},
  returnStep: () => {},
  getIgnoredColumnTarget: (columnName, resource) => (
    (
      ignoredColumnNames[resource].includes(columnName) ||
      ignoredColumnNames.default.includes(columnName)
    )
      ? 'ignore'
      : null
  )
};

const schema = Yup.object().shape({
  mappings: Yup.array().of(
    Yup.object().shape({
      target: Yup.string().nullable()
        .required('Selecione um campo para essa coluna')
    })
  )
});

const defaultValues = { mappings: [] };

function MapDataStep(props) {
  const {
    advanceStep,
    returnStep,
    nextStepButtonRef,
    previousStepButtonRef,
    initialData,
    resource,
    file,
    customFieldsColumns,
    onSubmit,
    getIgnoredColumnTarget
  } = props;

  const { user } = useAuth();
  const tracker = useTracking();

  const [mappingData, setMappingData] = useState(null);
  const [showErrors, setShowErrors] = useState(false);

  const createImportMappingsMutation = useCreateImportMappings();

  useEffect(() => {
    createImportMappingsMutation.mutate(
      {
        resource,
        file: file.response.body
      },
      { onSuccess: ({ data }) => setMappingData(data) }
    );
  }, []);

  const columnsTargets = mappingData ? mappingData.mapping.map((map) => map.target) : [];
  const tableColumnsNames = mappingData ? mappingData.mapping.map((map) => map.name) : [];

  const initialValues = useMemo(
    () => initialData || ({
      mappings: columnsTargets.map((target, index) => {
        const columnName = tableColumnsNames[index];
        const targetValue = target || getIgnoredColumnTarget(columnName, resource);
        return { target: targetValue };
      })
    }),
    [initialData, mappingData]
  );

  const requiredFields = useMemo(() => {
    if (!mappingData) {
      return [];
    }

    const { defaultColumns } = mappingData;

    const fields = Object.keys(defaultColumns);

    return fields.filter((field) => defaultColumns[field].required);
  }, [mappingData]);

  if (!mappingData) {
    return null;
  }

  const handleSubmit = (values, { setFieldError }) => {
    const isRequiredFieldsFilled = requiredFields.every(
      (field) => values.mappings.map((map) => map.target).includes(field)
    );

    if (!isRequiredFieldsFilled) {
      setFieldError('mappings', 'Preencha os campos obrigatórios (*)');
      return;
    }

    tracker.trackNewImportMapping({ user, entity: resource });
    onSubmit(values);
    advanceStep();
  };

  const finalColumns = { ...mappingData.defaultColumns, ...customFieldsColumns };

  return (
    <Form
      initialValues={initialValues}
      defaultValues={defaultValues}
      onSubmit={handleSubmit}
      validationSchema={schema}
      initialSubmit={false}
      name='import-mapping-form'
      className='d-flex flex-column flex-grow-1 flexible-height'
    >
      {
        ({ values, errors }) => (
          <>
            <h5 className='my-4 text-dark-gray'>
              Relacione os campos do Agendor correspondentes às colunas da sua planilha
            </h5>
            <div
              className={classnames(
                'd-flex',
                'flex-column',
                'flex-grow-1',
                'min-height-6',
                'px-8',
                'border',
                'border-light'
              )}
            >
              <div className='my-4 text-dark-gray'>
                Para importar empresas e seus contatos indique no mapeamento quais dados
                são relacionados.
              </div>

              {showErrors && errors.mappings &&
                <div>
                  <Alert
                    variant='danger'
                    className='my-0 w-100'
                    onClose={() => setShowErrors(false)}
                  >
                    {Array.isArray(errors.mappings)
                      ? `Por favor, informe qual coluna do Agendor corresponde a
                        coluna da empresa em sua planilha.`
                      : errors.mappings
                    }

                  </Alert>
                </div>
              }
              <div
                className={classnames(
                  'd-flex',
                  'flex-column',
                  'flex-grow-1',
                  'flexible-height',
                  'overflow-x-scroll',
                  'overflow-y-scroll',
                  'me-n4'
                )}
              >
                <div className='mb-3 py-3 ps-3 bg-light rounded text-dark-gray d-table sticky-top'>
                  <h5 className='mb-1'>Campos do Agendor</h5>

                  <MappingTable
                    resource={resource}
                    selectedColumns={values.mappings}
                    defaultColumns={finalColumns}
                  />
                </div>

                <div className='ps-3'>
                  <ImportTable
                    tableColumnsData={mappingData.mapping}
                    tableColumnsNames={tableColumnsNames}
                  />
                </div>

                <input
                  ref={previousStepButtonRef}
                  type='button'
                  className='hidden-button'
                  onClick={() => returnStep()}
                />

                <input
                  ref={nextStepButtonRef}
                  type='submit'
                  onClick={() => setShowErrors(true)}
                  className='hidden-button'
                />
              </div>
            </div>
          </>
        )
      }
    </Form>
  );
}

MapDataStep.propTypes = propTypes;
MapDataStep.defaultProps = defaultProps;

export default MapDataStep;
