/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';
import { sortBy } from 'lodash';
import ProposalIconLabel from '@/components/Proposal/ProposalIconLabel';
import ProposalLogo from '@/components/Proposal/ProposalLogo';
import { ProposalProducts } from '@/components/Proposal/ProposalProducts';
import { ProposalRichText } from '@/components/Proposal/ProposalRichText';
import { toShortTextualDateString, toDateString } from '@/date';
import { applyMask } from '@/masks';
import { PROPOSAL_SECTIONS_TYPE, getDocType } from '@/utils';
import { addDays } from 'date-fns';

const MEDIUM_OPACITY = 0.50;
const BANNER_WIDTH = '1280px';
const BANNER_HEIGHT = '320px';

ProposalDocumentBody.propTypes = {
  user: PropTypes.object,
  proposal: PropTypes.object,
  version: PropTypes.string,
  bannerBase64: PropTypes.string,
  logoBase64: PropTypes.string
};

ProposalDocumentBody.defaultProps = {
  user: {},
  proposal: {},
  version: '',
  bannerBase64: '',
  logoBase64: ''
};

const ProposalSectionComponent = {
  [PROPOSAL_SECTIONS_TYPE.RICH_TEXT]: ProposalRichText,
  [PROPOSAL_SECTIONS_TYPE.PRODUCTS]: ProposalProducts
};

function ProposalDocumentBody({
  user, proposal, version, bannerBase64, logoBase64
}) {
  const docType = getDocType(proposal?.cpfCnpj);
  const maskedCpfCnpj = applyMask(proposal?.cpfCnpj, docType);
  const allProposalModelSections = proposal?.proposalModelSections ?? [];
  const proposalModelSections = allProposalModelSections.filter(
    (section) => Boolean(!section?._destroy)
  );
  const orderedProposalModelSections = sortBy(proposalModelSections, 'position');
  const bgColor = proposal?.bgColor || (bannerBase64 ? '' : '#f0f0f7');
  const textColor = proposal?.textColor;
  const titleColorClass = 'text-darker-gray fw-bold mb-2';
  const contentColorClass = 'text-darker-gray mb-1';
  const hasEntityBox = proposal?.entityName || proposal?.entityWorkphone || proposal?.entityEmail;
  const hasOrganization = (
    proposal?.deal?.organization || proposal?.entityAddress || proposal?.entityCnpj
  );
  const currentDate = toShortTextualDateString(new Date());
  const css = document.querySelector('link[rel=stylesheet]')?.href;
  const hiddenFields = proposal?.hiddenFields;

  return (
    <html>
      <head>
        <meta charSet='utf-8' />
        <meta name='viewport' content='width=device-width, initial-scale=1' />
        <link href={css} rel='stylesheet' />
      </head>
      <body className='proposal-body'>
        <div className='position-absolute top-0 start-0 bg-white w-100 mb-3'>
          <div className='w-100 mw-100 mx-0 px-0'>
            {/*
              Como precisávamos implementar uma cor de filtro juntamente com
              a opção de ter imagem ao fundo e isso não era abrangido pela lib
              pdf-to-react via CSS backgroundBlendMode: 'overlay', tivemos que
              fazer essa sobreposição de elementos manualmente conforme as duas
              positions abaixo.
            */}
            <div
              className='w-100 mx-0 p-0 position-absolute object-fit-cover top-0 start-0'
              style={{
                width: BANNER_WIDTH,
                maxWidth: BANNER_WIDTH,
                minWidth: BANNER_WIDTH,
                height: BANNER_HEIGHT,
                maxHeight: BANNER_HEIGHT,
                minHeight: BANNER_HEIGHT,
                opacity: bannerBase64 ? 1 : MEDIUM_OPACITY,
                backgroundImage: bannerBase64 ? `url(${bannerBase64})` : null,
                backgroundSize: 'cover'
              }}
            />
            <div className='position-relative'>
              <div
                className='w-100 mx-0 p-0'
                style={{
                  height: BANNER_HEIGHT,
                  maxHeight: BANNER_HEIGHT,
                  minHeight: BANNER_HEIGHT,
                  backgroundColor: bgColor,
                  color: textColor
                }}
              >
                <div className='float-end clearfix pt-6 pe-8 w-50 text-end'>
                  { hiddenFields?.version ? null : version }
                </div>
                <div className='d-block'>
                  <div
                    className='d-inline-block text-center mx-auto float-start clearfix'
                    style={{ marginTop: '6.2rem', width: '16rem' }}
                  >
                    {logoBase64 ? <ProposalLogo src={logoBase64} /> : null}
                  </div>
                  <div className='d-inline-block w-auto mt-8'>
                    <span className='fw-bold'>
                      {proposal?.title}
                    </span>
                    <h1 className='fw-bold my-2' style={{ fontSize: '1.6rem' }}>
                      {proposal?.legalName}
                    </h1>
                    <span className='d-block mb-1 fw-bold'>
                      {
                        hiddenFields?.cpfCnpj
                          ? null
                          : `${docType ? `${docType.toUpperCase()}: ` : ''}${maskedCpfCnpj}`
                      }
                    </span>
                    <span className='d-block mb-1 fw-bold'>
                      {
                        hiddenFields?.address
                          ? null
                          : proposal?.address
                      }
                    </span>
                    <div className='d-inline fw-bold'>
                      {
                        hiddenFields?.expiredAt
                          ? null
                          : (
                            <>
                              <span className='text-capitalize'>
                                {currentDate}
                              </span>
                              &nbsp;|&nbsp;Valida até:&nbsp;
                              {toDateString(addDays(new Date(), proposal?.expiresInDays))}
                            </>
                          )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className='px-4'>
              <div className='d-block mx-4 mt-8'>
                <div
                  className='d-inline-block mb-2 align-top'
                  style={hiddenFields?.senderSection ? { width: '0%' } : { width: '32%' }}>
                  {
                    hiddenFields?.senderSection
                      ? null
                      : (
                        <>
                          <span className={titleColorClass}>
                            Proposta enviada por {user?.name}
                          </span>
                          <ProposalIconLabel
                            text={user?.email}
                            icon='proposal-icon-email'
                            className={contentColorClass}
                          />
                          <ProposalIconLabel
                            text={user?.workPhone}
                            icon='proposal-icon-call'
                            className={contentColorClass}
                          />
                        </>
                      )
                  }
                </div>
                <div
                  className='mb-2 align-top'
                  style={{
                    display: hasEntityBox ? 'inline-block' : 'none',
                    width: hiddenFields?.recipientSection ? '0%' : '32%'
                  }}
                >
                  {
                    hiddenFields?.recipientSection
                      ? null
                      : (
                        <>
                          <span className={titleColorClass}>
                            Para: {proposal?.entityName || 'não informado'}
                          </span>
                          <ProposalIconLabel
                            text={proposal?.entityEmail}
                            icon='proposal-icon-email'
                            className={contentColorClass}
                          />
                          <ProposalIconLabel
                            text={applyMask(proposal?.entityWorkPhone, 'phone')}
                            icon='proposal-icon-call'
                            className={contentColorClass}
                          />
                        </>
                      )
                  }
                </div>
                <div
                  className='mb-2 align-top'
                  style={{
                    display: hasOrganization ? 'inline-block' : 'none',
                    width: hiddenFields?.recipientOrganizationSection ? '0%' : '32%'
                  }}
                >
                  {
                    hiddenFields?.recipientOrganizationSection
                      ? null
                      : (
                        <>
                          <span className={titleColorClass}>
                            Dados da empresa
                          </span>
                          <ProposalIconLabel
                            text={proposal?.entityName}
                            icon='proposal-icon-org'
                            className={contentColorClass}
                          />
                          <ProposalIconLabel
                            text={applyMask(proposal?.entityCnpj, 'cnpj')}
                            icon='proposal-icon-org'
                            className={contentColorClass}
                          />
                          <ProposalIconLabel
                            text={proposal?.entityAddress}
                            icon='proposal-icon-visit'
                            className={contentColorClass}
                          />
                        </>
                      )
                  }
                </div>
              </div>
              {
                orderedProposalModelSections?.map((section, index) => {
                  const { sectionType, title, content } = section;
                  const SectionComponent = ProposalSectionComponent[sectionType];

                  return (
                    <SectionComponent
                      key={`${sectionType}_${index}`}
                      title={title}
                      content={content}
                      items={proposal?.entity_products}
                      hiddenFields={hiddenFields}
                    />
                  );
                })
              }
            </div>
          </div>
        </div>
      </body>
    </html>
  );
}

export default ProposalDocumentBody;
