import React, { useMemo, useState } from 'react';
import { Navigate, useLocation, useOutletContext } from 'react-router-dom';
import { useAlert } from 'react-alert';
import APIErrorMessage from '@/components/APIErrorMessage';
import DeleteModal from '@/feature/automation/DeleteModal';
import { templates, AutomationForm } from '@/feature/automation';
import { useAutomations, useDeleteAutomation, useUpdateAutomation } from '@/api';
import { getValidationErrors } from '@/utils';
import { useAutomationContext } from '@/pages/settings/automations/modal';
import { settingsAutomationsPath } from '@/routes';

function AutomationsEdit() {
  const location = useLocation();
  const id = location.state?.id;

  const { data: automations } = useAutomations({
    config: { refetchOnMount: false }
  });
  const data = automations?.data.find((automation) => automation.id === id);
  const type = `${data?.triggerName}_${data?.actionName}`;
  const template = templates[type];

  const alert = useAlert();
  const { onHide } = useOutletContext();
  const updateMutation = useUpdateAutomation();

  const onSubmit = (values, { setFieldError }) => (
    updateMutation.mutateAsync({ id: data.id, params: values }, {
      onSuccess: () => {
        alert.show(
          'Automação editada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );

        onHide();
      },
      onError: (err) => {
        const errors = getValidationErrors(err, 'automations');

        Object.entries(errors).forEach(([key, message]) => {
          setFieldError(key, message);
        });

        alert.show(
          <APIErrorMessage err={err} resource='automation' action='update' />,
          { variant: 'danger' }
        );
      }
    })
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDelete = () => setShowDeleteModal(true);
  const onHideDelete = () => setShowDeleteModal(false);
  const deleteMutation = useDeleteAutomation({
    config: {
      onSuccess: () => {
        alert.show(
          'Automação excluída com sucesso',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='automation' action='delete' />,
          { variant: 'danger' }
        );
        onHideDelete();
      }
    }
  });
  const onConfirmDelete = () => deleteMutation.mutateAsync({ id });
  const isDeleting = deleteMutation.isLoading;

  const { initialValues: contextValues } = useAutomationContext();
  const initialValues = useMemo(() => ({
    ...getInitialValues(data),
    ...contextValues
  }), [data, contextValues]);

  if (!template) {
    return <Navigate to={settingsAutomationsPath()} />;
  }

  return (
    <>
      <AutomationForm
        template={template}
        initialValues={initialValues}
        onSubmit={onSubmit}
        onDelete={onDelete}
        onCancel={onHide}
        title='Editar automação'
        saveButtonLabel='Salvar alterações'
      />

      <DeleteModal
        show={showDeleteModal}
        onConfirm={onConfirmDelete}
        onHide={onHideDelete}
        loading={isDeleting}
        disabled={isDeleting}
      />
    </>
  );
}

function getInitialValues(data) {
  return {
    trigger_name: data?.triggerName,
    trigger_params: data?.triggerParams,
    action_name: data?.actionName,
    action_params: data?.actionParams
  };
}

export default AutomationsEdit;
