import React, { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAlert } from 'react-alert';
import omit from 'lodash/omit';
import Modal from '@/components/Modal';
import ProposalForm from '@/components/Proposal/ProposalForm';
import ProposalPreview from '@/components/Proposal/ProposalPreview';
import ProposalPDF from '@/components/Proposal/ProposalPDF';
import ProposalLoading from '@/components/Proposal/ProposalLoading';
import ProposalRenameModal from '@/components/Proposal/ProposalRenameModal';
import { getInitialValues as getProductsInitialValues } from '@/components/Inputs/ProductsFields';
import { proposalModelKeys } from '@/api';
import { useAuth } from '@/lib/auth';
import { createUppy, useUppy } from '@/lib/uppy';
import { useTracking } from '@/lib/tracking';
import {
  PDF_EXTENSION,
  IMAGE_EXTENSIONS,
  JSON_EXTENSION,
  PROPOSAL_SECTIONS_TYPE,
  formatAddressLine
} from '@/utils';
import {
  currentDateInSlash, toShortTextualDateString,
  toUTCISOString
} from '@/date';

ProposalModal.propTypes = {
  deal: PropTypes.object,
  proposalModel: PropTypes.object,
  account: PropTypes.object,
  show: PropTypes.bool,
  entity: PropTypes.object,
  entityType: PropTypes.string,
  onHide: PropTypes.func,
  onFinish: PropTypes.func,
  onUpsertProposalModel: PropTypes.func,
  onCreateActivityProposal: PropTypes.func,
  onEditProposalModel: PropTypes.func,
  loading: PropTypes.bool,
  modalSize: PropTypes.string,
  isDisabledCreate: PropTypes.bool
};

ProposalModal.defaultProps = {
  deal: {},
  proposalModel: {},
  account: {},
  show: false,
  entity: {},
  entityType: '',
  onHide: () => { },
  onFinish: () => { },
  onUpsertProposalModel: () => { },
  onCreateActivityProposal: () => { },
  loading: true,
  modalSize: 'xxl',
  isDisabledCreate: false
};

const JSON_TYPE = 'application/json';
const PDF_TYPE = 'application/pdf';
const MAX_FILE_SIZE = 50;
const METADATA_IGNORED_KEYS = [
  'id',
  'name',
  'logo',
  'banner',
  'deal',
  'entity',
  'metadata'
];

const DEFAULT_EXPIRES_IN_DAYS = 30;

function ProposalModal(props) {
  const { deal, proposalModel, show, entity, entityType, onUpsertProposalModel,
    onHide, onCreateActivityProposal, loading, modalSize, isDisabledCreate } = props;

  const alert = useAlert();
  const tracker = useTracking();
  const currentDate = toShortTextualDateString(new Date());
  const versionTag = `${deal?.proposalDealCurrentVersion}-${deal?.id}-${currentDateInSlash()}`;
  const version = `proposta_v${versionTag}`;
  const filename = `${version}.pdf`;

  const { user } = useAuth();
  const queryClient = useQueryClient();

  const [pdfFile, setPdfFile] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [saving, setSaving] = useState(false);
  const [closing, setClosing] = useState(false);
  const [processPDF, setProcessPDF] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [proposal, setProposal] = useState();

  useEffect(() => {
    if (show && !loading) {
      const newProposal = {
        deal,
        entity,
        entityType,
        entityEmail: entity?.contact?.email,
        entityWorkPhone: entity?.contact?.workPhone,
        entityCnpj: entity?.cnpj,
        entityName: entity?.name,
        entityAddress: formatAddressLine(entity?.address),
        entity_products: getProductsInitialValues(deal?.entityProducts),
        expiresInDays: DEFAULT_EXPIRES_IN_DAYS,
        ...proposalModel
      };
      setProposal(newProposal);
    }
  }, [show, proposalModel, loading]);

  const createCustomUppy = (allowedFileTypes, maxFileSizeMega) => {
    const newUppy = createUppy({
      allowedFileTypes,
      maxFileSizeMega,
      maxNumberOfFiles: 1
    });

    newUppy
      .on('restriction-failed', (file) => {
        alert.show(
          `Tamanho máximo permitido é de ${maxFileSizeMega} MB.`,
          { variant: 'danger' }
        );
        newUppy.removeFile(file.id);
        setProcessing(false);
      })
      .on('upload-error', (file) => {
        alert.show(
          `Upload falhou do arquivo ${file.name}`,
          { variant: 'danger' }
        );
        newUppy.removeFile(file.id);
        setProcessing(false);
      })
      .on('complete', () => setProcessing(false));

    return newUppy;
  };

  const pdfUppy = useUppy(() => createCustomUppy([PDF_EXTENSION], MAX_FILE_SIZE));
  const jsonUppy = useUppy(() => createCustomUppy([JSON_EXTENSION], MAX_FILE_SIZE));
  const logoUppy = useUppy(() => createCustomUppy(IMAGE_EXTENSIONS, MAX_FILE_SIZE));
  const bannerUppy = useUppy(() => createCustomUppy(IMAGE_EXTENSIONS, MAX_FILE_SIZE));

  function resetUppys() {
    pdfUppy.reset();
    jsonUppy.reset();
    logoUppy.reset();
    bannerUppy.reset();
  }

  function clearMainState() {
    setProcessPDF(false);
    setSaving(false);
    setClosing(false);
    setProcessing(false);
    setShowRenameModal(false);
    setPdfFile(null);
    resetUppys();
  }

  const prepareMetadata = (metadataProps) => {
    const output = omit(metadataProps, METADATA_IGNORED_KEYS);
    return JSON.stringify({ version, ...output });
  };

  const prepareTracker = () => {
    const { proposalModelSections: sections } = proposal;
    const { PRODUCTS, RICH_TEXT } = PROPOSAL_SECTIONS_TYPE;

    const countSectionsText = sections?.filter(({
      sectionType, hidden
    }) => sectionType === RICH_TEXT && !hidden).length;
    const countSectionsProducts = sections?.filter(({
      sectionType, hidden
    }) => sectionType === PRODUCTS && !hidden).length;
    const hasSectionProducts = sections?.some(({ sectionType }) => sectionType === PRODUCTS);
    const hasDealProducts = deal?.entityProducts?.length > 0;
    const hasText = sections?.some(({ sectionType }) => sectionType === RICH_TEXT);
    return {
      user,
      countSectionsText,
      countSectionsProducts,
      hasText,
      hasLogo: proposal?.logo,
      hasBanner: proposal?.banner,
      hasProducts: hasSectionProducts && hasDealProducts
    };
  };

  const addFile = (customUppy, name, content, type) => {
    customUppy.reset();
    return customUppy.addFile({ name, type, data: new Blob([content], { type }) });
  };

  const listContentFiles = (customUppy) => customUppy
    .getFiles()
    .map((file) => file?.response?.body);
  const getContentFile = (customUppy) => listContentFiles(customUppy)?.[0];

  const uploadProposalFiles = (proposalData) => {
    const logo = proposalData?.logo;
    const banner = proposalData?.banner;
    const metadataFilename = 'metadata.json';
    const metadataContent = prepareMetadata(proposalData);

    addFile(jsonUppy, metadataFilename, metadataContent, JSON_TYPE);

    if (logo?.id && logo?.blob) {
      const logoFilename = logo.id.split('/').pop();
      addFile(logoUppy, logoFilename, logo.blob, logo.blob.type);
    }

    if (banner?.id && banner?.blob) {
      const bannerFilename = banner.id.split('/').pop();
      addFile(bannerUppy, bannerFilename, banner.blob, banner.blob.type);
    }
  };

  const handleCreatePDF = (pdf) => {
    setProcessPDF(false);
    setPdfFile(pdf);
    addFile(pdfUppy, filename, pdf.blob, PDF_TYPE);
  };

  const handlePreview = (newProposal) => {
    uploadProposalFiles(newProposal);
    setProposal(newProposal);
    setProcessPDF(true);
  };

  const handleClose = async () => {
    setClosing(true);
    await queryClient.invalidateQueries(proposalModelKeys.all);
    clearMainState();
    onHide();
  };

  const handleBackToForm = () => {
    setPdfFile(null);
    setClosing(false);
    setSaving(false);
    setProcessing(false);
    setShowRenameModal(false);
    setProcessPDF(false);
  };

  const handleCreateProposal = () => {
    setSaving(true);

    const activity = {
      text: `Envio de proposta para: ${proposal?.entityName || 'não informado'}.`,
      type: 'proposal',
      assigned_user_ids: [user.id],
      due_at: toUTCISOString(new Date()),
      entity_id: deal.id,
      entity: 'deal',
      images: [],
      documents: listContentFiles(pdfUppy),
      proposal: {
        name: version,
        deal_id: deal.id,
        metadata: getContentFile(jsonUppy),
        logo: getContentFile(logoUppy) || proposal?.logo,
        banner: getContentFile(bannerUppy) || proposal?.banner
      }
    };

    onCreateActivityProposal(activity, () => {
      clearMainState();
      tracker.trackProposalCreated(prepareTracker());
    });
  };

  const handleUpsertProposalModel = (params, done) => {
    setSaving(true);
    onUpsertProposalModel(params, (err) => {
      setSaving(false);
      done?.(err);
    });
  };

  const handleUpdateProposalModel = () => {
    handleUpsertProposalModel(proposal, (err) => {
      if (!err) {
        tracker.trackProposalModelUpdated(prepareTracker());
      }
    });
  };

  const handleCopyProposalModel = () => {
    const params = {
      ...proposal,
      id: null,
      copy: true,
      logo: getContentFile(logoUppy) || proposal?.logo,
      banner: getContentFile(bannerUppy) || proposal?.banner
    };
    handleUpsertProposalModel(params, (err) => {
      if (!err) {
        tracker.trackProposalModelCreated(prepareTracker());
      }
    });
  };

  // Essa ação não cria modelo, somente abre modal para informar nome do modelo
  const handleSetNameForProposalModel = () => {
    setShowRenameModal(true);
  };

  const handleCreateProposalModel = ({ name }, done) => {
    const params = {
      ...proposal,
      name,
      id: null,
      copy: false,
      logo: getContentFile(logoUppy) || proposal?.logo,
      banner: getContentFile(bannerUppy) || proposal?.banner
    };
    handleUpsertProposalModel(params, (err) => {
      if (!err) {
        setShowRenameModal(false);
        tracker.trackProposalModelCreated(prepareTracker());
      }
      done?.(err);
    });
  };

  const renderMainContent = () => {
    if (pdfFile) {
      return (
        <ProposalPreview
          preview={pdfFile.preview}
          saving={saving}
          update={Boolean(proposal?.id)}
          onCancel={handleBackToForm}
          onCreateProposal={handleCreateProposal}
          onCreateProposalModel={handleSetNameForProposalModel}
          onUpdateProposalModel={handleUpdateProposalModel}
          onCopyProposalModel={handleCopyProposalModel}
          isDisabledCreate={isDisabledCreate}
        />
      );
    }
    if ((loading || processing || processPDF) && !closing) {
      return (
        <ProposalLoading
          title='Preparando a proposta'
          subtitle='Aguarde só um pouco, estamos carregando os dados.'
        />
      );
    }
    if (proposal) {
      return (
        <ProposalForm
          user={user}
          proposal={proposal}
          version={version}
          processing={processing}
          currentDate={currentDate}
          onCancel={handleClose}
          onSubmit={handlePreview}
        />
      );
    }
    return null;
  };

  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        bodyClassName='py-1 px-0 h-75 mh-75 vh-75 min-vh-75'
        backdrop='static'
        size={modalSize}
      >
        <div className={classnames(
          'modal-header modal-header-sticky w-100 rounded-0',
          'd-block bg-white mt-0 border-top-left-radius-0 px-8'
        )}>
          <h2 className='text-dark-gray me-2 mt-1'>
            {proposalModel?.name ?? 'Nova proposta'}
          </h2>
        </div>
        {renderMainContent()}
      </Modal>
      <ProposalRenameModal
        show={showRenameModal}
        title='Salvar modelo de proposta'
        icon='proposal'
        onSubmit={handleCreateProposalModel}
        onHide={() => setShowRenameModal(false)}
        proposalModel={proposal}
      />
      <ProposalPDF
        process={processPDF}
        filename={filename}
        user={user}
        proposal={proposal}
        onCreate={handleCreatePDF}
        version={version}
      />
    </>
  );
}

export default ProposalModal;
