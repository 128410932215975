/* eslint complexity: 0 */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import sortBy from 'lodash/sortBy';
import { applyMask } from '@/masks';
import {
  PROPOSAL_SECTIONS_TYPE, getDocType, PROPOSAL_SECTIONS_TITLE
} from '@/utils';
import { useTracking } from '@/lib/tracking';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import HookForm from '@/components/HookForm';
import HookFormField from '@/components/HookFormField';
import LoadingButton from '@/components/LoadingButton';
import BannerArea from '@/components/BannerArea';
import Popover from '@/components/Popover';
import Tooltip from '@/components/Tooltip';
import ProposalLoading from '@/components/Proposal/ProposalLoading';
import {
  EditInPlace, ImagePicker, TextInput, MaskedInput, IntegerInput
} from '@/components/Inputs';
import { ProposalProductsInput } from '@/components/Proposal/ProposalProducts';
import { ProposalRichTextInput } from '@/components/Proposal/ProposalRichText';
import { toDateString } from '@/date';
import ProposalAddSection from '@/components/Proposal/ProposalAddSection';
import ProposalDeleteSection from '@/components/Proposal/ProposalDeleteSection';
import ToggleFieldVisibility from '@/components/ToggleFieldVisibility';
import strings from '@/strings';
import logoImage from 'images/logo-proposal-footer.png';
import { addDays } from 'date-fns';

ProposalForm.propTypes = {
  user: PropTypes.object,
  proposal: PropTypes.object,
  version: PropTypes.string,
  processing: PropTypes.bool,
  currentDate: PropTypes.string,
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func
};

ProposalForm.defaultProps = {
  user: {},
  proposal: {},
  version: '',
  processing: false,
  currentDate: '',
  onSubmit: () => { },
  onCancel: () => { }
};

const SectionInputComponent = {
  [PROPOSAL_SECTIONS_TYPE.RICH_TEXT]: ProposalRichTextInput,
  [PROPOSAL_SECTIONS_TYPE.PRODUCTS]: ProposalProductsInput
};

function ProposalForm({
  user, processing, proposal, version, currentDate, onSubmit, onCancel
}) {
  const tracker = useTracking();
  const [resetFormValues, setResetFormValues] = useState(null);
  const [hideSecundaryElement, setHideSecundaryElement] = useState(false);

  const handleHideSecundaryElement = (value) => {
    setHideSecundaryElement(value);
  };

  const handleCancelWithIntercomTracker = () => {
    tracker.trackProposalNotCreated({ user });
    onCancel();
  };

  useEffect(() => {
    if (proposal && resetFormValues) {
      resetFormValues(proposal);
    }
  }, [proposal, resetFormValues]);

  return (
    <HookForm
      name='proposal-form'
      onSubmit={onSubmit}
      defaultValues={proposal}
    >
      {({ formState, watch, reset, setValue }) => {
        if (reset) {
          setResetFormValues(() => reset);
        }

        const values = watch();
        const submitting = processing || formState.isSubmitting;

        const { hiddenFields } = values;
        const docType = getDocType(values?.cpfCnpj);
        const maskedCpfCnpj = applyMask(values?.cpfCnpj, docType);
        const docTypeLabel = docType ? `${docType.toUpperCase()}: ` : '';
        const maskedEntityCnpj = applyMask(values?.entityCnpj, 'cnpj');
        const cpfCnpjLabel = maskedCpfCnpj || 'CPF/CNPJ não informado';
        const titleLabel = values?.title || 'Título não informado';
        const addressLabel = values?.address || 'Endereço não informado';
        const legalNameLabel = values?.legalName || 'Razão social não informada';

        const allProposalModelSections = values?.proposalModelSections ?? [];
        const proposalModelSections = sortBy(allProposalModelSections, 'position');
        const visibleSectionsCount = proposalModelSections
          .filter((section) => !section.hidden).length;
        const hasProducts = Boolean(proposalModelSections?.find(
          ({ sectionType, hidden }) => sectionType === PROPOSAL_SECTIONS_TYPE.PRODUCTS && !hidden
        ));

        return (
          <>
            {
              submitting
                ? (
                  <ProposalLoading
                    title={'Preparando a proposta'}
                    subtitle={'Aguarde só um pouco, estamos preparando a proposta.'}
                  />
                )
                : (
                  <>
                    <HookFormField
                      as={BannerArea}
                      name='banner'
                      className='mx-0 p-0'
                      bgColor={values?.bgColor}
                      textColor={values?.textColor}
                      onChange={(banner) => setValue('banner', banner)}
                      onChangeColors={({ bgColor, textColor }) => {
                        setValue('bgColor', bgColor);
                        setValue('textColor', textColor);
                      }}
                    >
                      <div className='d-flex readable-component flex-row-reverse mt-6 pe-8'>
                        <ToggleFieldVisibility
                          field='version'
                          hiddenFields={hiddenFields}
                          hideSecundaryElement={hideSecundaryElement}
                          onChangeFieldVisibility={(field, value) => {
                            setValue(`hiddenFields.${field}`, value);
                          }}
                        />
                        <Tooltip
                          content={strings.labels.proposal_models.layout.code}
                          placement='top'
                        >
                          <div className={classnames(
                            'cursor-default',
                            { 'text-medium-gray': hiddenFields?.version }
                          )}>
                            {version}
                          </div>
                        </Tooltip>
                      </div>
                      <Col sm={3} className='text-center mx-auto'>
                        <HookFormField
                          as={ImagePicker}
                          name='logo'
                          label='logotipo'
                          iconName='organization'
                          className='d-inline-block ms-4'
                          onChange={(logo) => setValue('logo', logo)}
                        />
                      </Col>
                      <Col sm={9} className='mt-n4'>
                        <EditInPlace
                          as={TextInput}
                          name='title'
                          value={values?.title}
                          className='d-block width-5'
                          onCancel={({ title }) => setValue('title', title)}
                          onConfirm={({ title }, done) => {
                            setValue('title', title);
                            done();
                          }}
                        >
                          <span className='fw-bold mb-2 d-block'>
                            {titleLabel}
                          </span>
                        </EditInPlace>
                        <EditInPlace
                          as={TextInput}
                          name='legalName'
                          value={values?.legalName}
                          className='d-block width-6'
                          onCancel={({ legalName }) => setValue('legalName', legalName)}
                          onConfirm={({ legalName }, done) => {
                            setValue('legalName', legalName);
                            done();
                          }}
                        >
                          <h1 className='fw-bold mb-2 d-block'>
                            {legalNameLabel}
                          </h1>
                        </EditInPlace>
                        <div className='d-flex readable-component'>
                          <EditInPlace
                            as={MaskedInput}
                            name='cpfCnpj'
                            maskType='cpf_cnpj'
                            value={values?.cpfCnpj}
                            className='d-block width-5'
                            hideSecundaryElement={handleHideSecundaryElement}
                            onCancel={({ cpfCnpj }) => setValue('cpfCnpj', cpfCnpj)}
                            onConfirm={({ cpfCnpj }, done) => {
                              setValue('cpfCnpj', cpfCnpj);
                              done();
                            }}
                          >
                            <span className={classnames(
                              'fw-bold mb-1 d-block',
                              { 'text-medium-gray': hiddenFields?.cpfCnpj }
                            )}>
                              {docTypeLabel}{cpfCnpjLabel}
                            </span>
                          </EditInPlace>
                          <ToggleFieldVisibility
                            field='cpfCnpj'
                            hiddenFields={hiddenFields}
                            hideSecundaryElement={hideSecundaryElement}
                            onChangeFieldVisibility={(field, value) => {
                              setValue(`hiddenFields.${field}`, value);
                            }}
                          />
                        </div>
                        <div className='d-flex readable-component'>
                          <EditInPlace
                            as={TextInput}
                            name='address'
                            value={values?.address}
                            className='d-block width-6'
                            hideSecundaryElement={handleHideSecundaryElement}
                            onCancel={({ address }) => setValue('address', address)}
                            onConfirm={({ address }, done) => {
                              setValue('address', address);
                              done();
                            }}
                          >
                            <span className={classnames(
                              'fw-bold mb-1 d-block',
                              { 'text-medium-gray': hiddenFields?.address }
                            )}>
                              {addressLabel}
                            </span>
                          </EditInPlace>
                          <ToggleFieldVisibility
                            field='address'
                            hiddenFields={hiddenFields}
                            hideSecundaryElement={hideSecundaryElement}
                            onChangeFieldVisibility={(field, value) => {
                              setValue(`hiddenFields.${field}`, value);
                            }}
                          />
                        </div>
                        <div className='d-flex readable-component fw-bold'>
                          <div className={classnames(
                            'd-flex',
                            { 'text-medium-gray': hiddenFields?.expiredAt }
                          )}>
                            <span className='text-capitalize'>{currentDate}</span>
                            &nbsp;|&nbsp;Válida até:&nbsp;
                            {toDateString(addDays(new Date(), values?.expiresInDays))}
                            <Popover
                              placement='bottom-end'
                              showArrow
                              content={({ onHide }) => (
                                <div className='d-flex flex-column justify-content-center'>
                                  <span className='fw-bold text-dark mt-2'>
                                    Alterar prazo de validade
                                  </span>
                                  <span className='text-dark-gray my-2'>
                                    Defina por quantos dias a sua proposta <br /> será válida.
                                  </span>
                                  <HookFormField
                                    as={IntegerInput}
                                    name='expiresInDays'
                                    label='Quantidade de dias'
                                    className='mb-1'
                                    maxLength={3}
                                    onChange={
                                      (expiresInDays) => setValue('expiresInDays', expiresInDays)
                                    }
                                  />
                                  <div className='d-flex justify-content-end mt-2'>
                                    <Button
                                      variant='light'
                                      onClick={() => {
                                        setValue('expiresInDays', proposal?.expiresInDays);
                                        onHide();
                                      }}
                                      className='me-2'
                                    >
                                      Cancelar
                                    </Button>
                                    <Button
                                      onClick={onHide}
                                      variant='primary'
                                      type='submit'
                                    >
                                      Aplicar
                                    </Button>
                                  </div>
                                </div>
                              )}
                            >
                              <div className='readable-component'>
                                <div className='readable-component-button'>
                                  <Icon name='edit-field' className='cursor-pointer text-primary' />
                                </div>
                              </div>
                            </Popover>
                          </div>
                          <ToggleFieldVisibility
                            field='expiredAt'
                            hiddenFields={hiddenFields}
                            hideSecundaryElement={hideSecundaryElement}
                            onChangeFieldVisibility={(field, value) => {
                              setValue(`hiddenFields.${field}`, value);
                            }}
                          />
                        </div>
                      </Col>
                    </HookFormField>
                    <Row className='m-8 py-2 cursor-default'>
                      <Col sm={4} className='ps-6'>
                        <div className={classnames(
                          { 'text-medium-gray': hiddenFields?.senderSection }
                        )}>
                          <div className='d-flex readable-component fw-bold'>
                            Proposta enviada por {user?.name || 'não informado'}
                            <ToggleFieldVisibility
                              field='senderSection'
                              hiddenFields={hiddenFields}
                              hideSecundaryElement={hideSecundaryElement}
                              onChangeFieldVisibility={(field, value) => {
                                setValue(`hiddenFields.${field}`, value);
                              }}
                            />
                          </div>
                          <div>
                            <Icon className='me-1' name='email' size='sm' />
                            <span>
                              {user?.email || 'Indefinido'}
                            </span>
                          </div>
                          <div>
                            <Icon className='me-1' name='call' size='sm' />
                            <span>
                              {applyMask(user?.workPhone, 'phone') ?? 'Indefinido'}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col sm={4}>
                        <div className={classnames(
                          { 'text-medium-gray': hiddenFields?.recipientSection }
                        )}>
                          <div className='d-flex readable-component fw-bold'>
                            <EditInPlace
                              as={TextInput}
                              name='entityName'
                              value={values?.entityName}
                              className='width-5'
                              hideSecundaryElement={handleHideSecundaryElement}
                              onCancel={({ entityName }) => setValue('entityName', entityName)}
                              onConfirm={({ entityName }, done) => {
                                setValue('entityName', entityName);
                                done();
                              }}
                            >
                              Para:&nbsp;{values?.entityName || 'não informado'}
                            </EditInPlace>
                            <ToggleFieldVisibility
                              field='recipientSection'
                              hiddenFields={hiddenFields}
                              hideSecundaryElement={hideSecundaryElement}
                              onChangeFieldVisibility={(field, value) => {
                                setValue(`hiddenFields.${field}`, value);
                              }}
                            />
                          </div>
                          <EditInPlace
                            as={TextInput}
                            name='entityEmail'
                            value={values?.entityEmail}
                            className='width-5'
                            readableClassName='text-nowrap'
                            placeholder='Informe o e-mail de contato'
                            onCancel={({ entityEmail }) => setValue('entityEmail', entityEmail)}
                            onConfirm={({ entityEmail }, done) => {
                              setValue('entityEmail', entityEmail);
                              done();
                            }}
                          >
                            <div className='max-width-5'>
                              <Icon className='me-1' name='email' size='sm' />
                              <span className='d-inline text-wrap word-wrap'>
                                {values?.entityEmail || 'Indefinido'}
                              </span>
                            </div>
                          </EditInPlace>
                          <EditInPlace
                            as={MaskedInput}
                            name='entityWorkPhone'
                            value={values?.entityWorkPhone}
                            maskType='phone'
                            autoComplete='off'
                            placeholder='Informe o telefone de contato'
                            className='width-5'
                            onCancel={({ entityWorkPhone }) => {
                              setValue('entityWorkPhone', entityWorkPhone);
                            }}
                            onConfirm={({ entityWorkPhone }, done) => {
                              setValue('entityWorkPhone', entityWorkPhone);
                              done();
                            }}
                          >
                            <div className='max-width-5'>
                              <Icon className='me-1' name='call' size='sm' />
                              <span className='d-inline text-wrap word-wrap'>
                                {applyMask(values?.entityWorkPhone, 'phone') || 'Indefinido'}
                              </span>
                            </div>
                          </EditInPlace>
                        </div>
                      </Col>
                      {
                        values?.deal?.organization
                          ? (
                            <Col sm={4}>
                              <div className={classnames(
                                { 'text-medium-gray': hiddenFields?.recipientOrganizationSection }
                              )}>
                                <div className='d-flex readable-component fw-bold'>
                                  <span>Dados da empresa</span>
                                  <ToggleFieldVisibility
                                    field='recipientOrganizationSection'
                                    hiddenFields={hiddenFields}
                                    hideSecundaryElement={hideSecundaryElement}
                                    onChangeFieldVisibility={(field, value) => {
                                      setValue(`hiddenFields.${field}`, value);
                                    }}
                                  />
                                </div>
                                <EditInPlace
                                  as={TextInput}
                                  name='entityName'
                                  value={values?.entityName}
                                  className='width-5'
                                  placeholder='Informe nome da empresa'
                                  hideSecundaryElement={handleHideSecundaryElement}
                                  onCancel={({ entityName }) => setValue('entityName', entityName)}
                                  onConfirm={({ entityName }, done) => {
                                    setValue('entityName', entityName);
                                    done();
                                  }}
                                >
                                  <div className='max-width-5'>
                                    <Icon className='me-1' name='organization' size='sm' />
                                    <span className='d-inline text-wrap word-wrap'>
                                      {values?.entityName || 'Indefinido'}
                                    </span>
                                  </div>
                                </EditInPlace>
                                <EditInPlace
                                  as={MaskedInput}
                                  name='entityCnpj'
                                  value={values?.entityCnpj}
                                  maskType='cnpj'
                                  autoComplete='off'
                                  placeholder='Informe CNPJ da empresa'
                                  className='width-5'
                                  onCancel={({ entityCnpj }) => setValue('entityCnpj', entityCnpj)}
                                  onConfirm={({ entityCnpj }, done) => {
                                    setValue('entityCnpj', entityCnpj);
                                    done();
                                  }}
                                >
                                  <div className='max-width-5'>
                                    <Icon className='me-1' name='organization' size='sm' />
                                    <span className='d-inline text-wrap word-wrap'>
                                      {maskedEntityCnpj || 'Indefinido'}
                                    </span>
                                  </div>
                                </EditInPlace>
                                <EditInPlace
                                  as={TextInput}
                                  name='entityAddress'
                                  value={values?.entityAddress}
                                  className='width-5'
                                  placeholder='Informe endereço completo'
                                  onCancel={({ entityAddress }) => {
                                    setValue('entityAddress', entityAddress);
                                  }}
                                  onConfirm={({ entityAddress }, done) => {
                                    setValue('entityAddress', entityAddress);
                                    done();
                                  }}
                                >
                                  <div className='max-width-5'>
                                    <Icon className='me-1' name='visit' size='sm' />
                                    <div className='d-inline text-wrap word-wrap'>
                                      {values?.entityAddress || 'Indefinido'}
                                    </div>
                                  </div>
                                </EditInPlace>
                              </div>
                            </Col>
                          )
                          : null
                      }
                    </Row>
                    {
                      proposalModelSections?.map((section, index) => {
                        const { sectionType, title, content, hidden } = section;
                        const InputComponent = SectionInputComponent[sectionType];
                        if (hidden) {
                          return null;
                        }

                        return (
                          <div key={`${sectionType}_${index}`}>
                            <InputComponent
                              name={`proposalModelSections.${index}`}
                              title={title}
                              content={content}
                              hiddenFields={hiddenFields}
                              onChangeFieldVisibility={(field, value) => {
                                setValue(`hiddenFields.${field}`, value);
                              }}
                              actions={
                                <ProposalDeleteSection
                                  section={section}
                                  disabled={visibleSectionsCount <= 1}
                                  onDeleteSection={(deletedSection) => {
                                    proposalModelSections[index] = deletedSection;
                                    setValue('proposalModelSections', proposalModelSections);
                                  }}
                                />
                              }
                              onChangeTitle={(newTitle) => {
                                proposalModelSections[index].title = newTitle;
                                setValue('proposalModelSections', proposalModelSections);
                              }}
                              onChangeContent={(newContent) => {
                                proposalModelSections[index].content = newContent;
                                setValue('proposalModelSections', proposalModelSections);
                              }}
                              onChangeProducts={(newProducts) => {
                                setValue('entity_products', newProducts);
                              }}
                            />
                            <ProposalAddSection
                              onAddSection={(newSectionType) => {
                                const position = index + 1;
                                proposalModelSections.splice(position, 0, {
                                  sectionType: newSectionType,
                                  title: PROPOSAL_SECTIONS_TITLE[newSectionType],
                                  content: '',
                                  position
                                });
                                const updated = proposalModelSections.map((item, newPos) => ({
                                  ...item,
                                  position: newPos
                                }));
                                setValue('proposalModelSections', updated);
                              }}
                              hasProducts={hasProducts}
                            />
                          </div>
                        );
                      })
                    }

                    <Row className='m-6 text-darker-gray text-small'>
                      <Col xs={7} className='ps-8'>
                        <div className='d-flex readable-component align-items-center'>
                          <span className={classnames({
                            'text-medium-gray': hiddenFields?.footerSection
                          })}>
                            {legalNameLabel}&nbsp;|&nbsp;{cpfCnpjLabel}
                            <br />
                            Endereço:&nbsp;{values?.address}
                          </span>
                          <ToggleFieldVisibility
                            field='footerSection'
                            hiddenFields={hiddenFields}
                            hideSecundaryElement={hideSecundaryElement}
                            onChangeFieldVisibility={(field, value) => {
                              setValue(`hiddenFields.${field}`, value);
                            }}
                          />
                        </div>
                      </Col>
                      <Col
                        xs={5}
                        className='d-inline-flex pe-8 justify-content-end align-items-center'
                      >
                        <div className='d-flex readable-component align-items-center'>
                          <span className={classnames(
                            { 'text-medium-gray': hiddenFields?.footerLogo }
                          )}>
                            Powered by <img src={logoImage} width={67} height={17} alt='logo' />
                          </span>
                          <ToggleFieldVisibility
                            field='footerLogo'
                            hiddenFields={hiddenFields}
                            hideSecundaryElement={hideSecundaryElement}
                            onChangeFieldVisibility={(field, value) => {
                              setValue(`hiddenFields.${field}`, value);
                            }}
                          />
                        </div>
                      </Col>
                    </Row>
                  </>
                )
            }
            <div className='position-sticky bg-white shadow-up bottom-0 left-0 w-100 z-index-1060'>
              <div className='d-flex justify-content-end px-8 me-2 py-6'>
                <Button
                  onClick={handleCancelWithIntercomTracker}
                  disabled={submitting}
                  className='me-2'
                  variant='light'
                >
                  Voltar
                </Button>
                <LoadingButton
                  isLoading={submitting}
                  loadingText='Aguarde...'
                  type='submit'
                >
                  Pré-visualizar
                </LoadingButton>
              </div>
            </div>
          </>
        );
      }}
    </HookForm>
  );
}

export default ProposalForm;
