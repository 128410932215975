import React from 'react';

import ABTest from '@/components/ABTest';
import OngoingChecklistDropdown from '@/feature/growth/OngoingChecklistDropdown';

import { useAuth } from '@/lib/auth';

function OngoingChecklist() {
  const { user } = useAuth();

  return (
    <ABTest name='ongoing_checklist' identifier={user.account.id}>
      <ABTest.Variant name='control' />

      <ABTest.Variant name='test'>
        <OngoingChecklistDropdown />
      </ABTest.Variant>
    </ABTest>
  );
}

export default OngoingChecklist;
