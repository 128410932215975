/* eslint-disable no-magic-numbers */
import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import Truncate from '@/components/Truncate';
import Icon from '@/components/Icon';
import Button from '@/components/Button';
import { canEditDealStage } from '@/policies';
import Authorization from '@/components/Authorization';
import ReactSlider from '@/components/ReactSlider';
import { useScreenSize } from '@/hooks';

StagesButtons.defaultProps = {
  deal: {},
  stages: [],
  onClick: () => {}
};

StagesButtons.propTypes = {
  deal: PropTypes.object,
  stages: PropTypes.array,
  onClick: PropTypes.func
};

const SLIDES_TO_SCROLL_SM = 1;
const SLIDES_TO_SCROLL = 3;
const MAX_SM_SLIDES = 2;
const MAX_SLIDES = 4;

function StagesButtons({ deal, stages, onClick }) {
  const indexOfActive = stages
    .map((stage) => stage.id)
    .indexOf(deal?.stage?.id);

  const [slidePosition, setSlidePosition] = useState(indexOfActive);
  const ref = useRef();
  const sliderRef = ref?.current;
  let slidesToShow = 4;

  useEffect(() => {
    sliderRef?.slickGoTo?.(slidePosition);
  }, [sliderRef, slidePosition]);

  const stagesLength = stages.length;
  const { isSM } = useScreenSize();

  slidesToShow = Math.min(stagesLength, slidesToShow);
  const slideNext = () => sliderRef?.slickNext();
  const slidePrev = () => sliderRef?.slickPrev();

  const settings = {
    arrows: false,
    dots: false,
    infinite: false,
    swipe: false,
    slidesToShow,
    slidesToScroll: SLIDES_TO_SCROLL,
    settings: {
      slidesToShow: MAX_SLIDES,
      slidesToScroll: SLIDES_TO_SCROLL
    },
    afterChange: (current) => setSlidePosition(current),
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: MAX_SM_SLIDES,
          slidesToScroll: SLIDES_TO_SCROLL_SM
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: MAX_SM_SLIDES,
          slidesToScroll: SLIDES_TO_SCROLL_SM
        }
      }
    ]
  };

  const stagesWithStates = stages.map((stage, index) => {
    const isPrevActive = index < indexOfActive;
    const isActive = index === indexOfActive;
    const isNextActive = !isActive && !isPrevActive;
    return { ...stage, isPrevActive, isActive, isNextActive };
  });

  const SlideButton = ({ direction }) => {
    const [colorLight, setColorLight] = useState('bg-white');
    const isPrevDirection = direction === 'prev';
    const isNextDirection = direction === 'next';

    const minStagesLength = isSM ? MAX_SM_SLIDES : MAX_SLIDES;
    const showArrowButton = stagesLength > minStagesLength;
    const slideLimit = stagesLength - minStagesLength;
    const isStartPosition = isPrevDirection && slidePosition === 0;
    const isEndPosition = isNextDirection && slidePosition > slideLimit;

    return (
      showArrowButton
        ? (
          <Button
            onMouseEnter={() => setColorLight('bg-light rounded-2')}
            onMouseLeave={() => setColorLight('bg-white')}
            disabled={isStartPosition || isEndPosition}
            onClick={isNextDirection ? slideNext : slidePrev}
            className={classnames(
              'border-white',
              `rounded-1 px-2 ${colorLight}`
            )}
          >
            <Icon
              className='text-darker-gray'
              name={isNextDirection ? 'arrow-right' : 'arrow-left'}
            />
          </Button>
        )
        : null
    );
  };

  const handleStageChange = (id, index) => {
    onClick(id);
    setSlidePosition(index);
  };

  return (
    <div className={classnames(
      'd-flex flex-fill mb-1',
      { 'width-6': isSM },
      { 'width-7': !isSM }
    )}>
      <SlideButton direction='prev' />
      <Authorization tooltipContainerClassName='w-100' policy={canEditDealStage}>
        <ReactSlider
          className='px-1 my-auto'
          ref={ref}
          settings={settings}
        >
          {
            stagesWithStates.map(({ id, name, isPrevActive, isActive, isNextActive }, index) => {
              const isFirstStage = index === 0;

              return (
                <div key={id} onClick={() => handleStageChange(id, index)} className={classnames(
                  'py-2 d-inline-flex',
                  { 'breadcrumb-arrow': !isFirstStage },
                  { 'breadcrumb-arrow-first': isFirstStage },
                  { 'text-darker-gray': !isPrevActive },
                  { 'bg-light-primary ': isPrevActive },
                  { 'bg-primary text-white': isActive },
                  { 'bg-white': isNextActive }
                )} >
                  <Truncate as='div' className='fw-bold mx-auto px-7'>
                    {name}
                  </Truncate>
                </div>
              );
            })
          }
        </ReactSlider>
      </Authorization>
      <SlideButton direction='next' />
    </div>
  );
}

export default StagesButtons;
