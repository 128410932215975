import { useQuery } from 'react-query';
import { get } from '@/lib/fetch';

export const proposalsKeys = {
  all: ['proposals'],
  lists: () => [...proposalsKeys.all, 'list'],
  list: (params) => [...proposalsKeys.lists(), params],
  details: () => [...proposalsKeys.all, 'detail'],
  detail: (id) => [...proposalsKeys.details(), id]
};

export async function getProposals(params = {}) {
  const { data } = await get('/proposals', params);

  return data;
}

export function useProposals({ params = {}, config = {} } = {}) {
  return useQuery({
    ...config,
    queryKey: proposalsKeys.list(params),
    queryFn: () => getProposals(params)
  });
}
