import React from 'react';
import PropTypes from 'prop-types';
import Base from 'react-bootstrap/Button';
import Link from '@/components/Link';

const propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'danger',
    'dark',
    'dark-gray',
    'white',
    'light',
    'light-blue',
    'light-green',
    'light-primary',
    'light-red',
    'lighter-blue',
    'light-yellow',
    'light-gray',
    'lighter-gray',
    'link',
    'outline-danger',
    'outline-dark-gray',
    'outline-darker-gray',
    'outline-primary',
    'transparent-light',
    'transparent-dark',
    'transparent-green'
  ]),
  href: PropTypes.string,
  target: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  /*
   * A prop `as` define qual o elemento que será renderizado. Por padrão, é
   * usado `button`. Em casos em que não queremos o estado "ativo" de um botão
   * após o clique do mesmo (como se fosse um toggle), utilizamos o `as` como
   *`label`, replicando o resultado final do componente `ToggleButton`. Os
   * casos que temos disso estão nos componentes `FieldsPopover` e `Drawer`.
   */
  as: PropTypes.elementType
};

const defaultProps = {
  className: '',
  variant: 'primary',
  size: 'md'
};

const Button = React.forwardRef(
  (props, ref) => {
    const { children, href, ...rest } = props;

    const Component = href ? Link : 'button';

    return (
      <Base
        ref={ref}
        as={Component}
        href={href}
        { ...rest }
      >
        {children}
      </Base>
    );
  }
);

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;
Button.displayName = 'Button';

export default Button;
