import React from 'react';
import PropTypes from 'prop-types';
import Col from 'react-bootstrap/Col';
import { Checkbox } from '@/components/Inputs';
import Card from '@/components/Card';

const propTypes = {
  option: PropTypes.shape({
    value: PropTypes.string.isRequired,
    icon: PropTypes.node.isRequired,
    title: PropTypes.node.isRequired,
    text: PropTypes.node
  }).isRequired,
  children: PropTypes.node,
  onChange: PropTypes.func,
  checked: PropTypes.bool
};

function NeedInputCard({ option, children, onChange, checked }) {
  return (
    <Col sm={6} lg={3} >
      <Card
        border='light-primary'
        bg='dark-light'
        className={`
          border-1
          py-3
          px-4
          min-height-100
          hover-bg-light
          text-center
          text-dark-gray
        `}
      >
        <Checkbox
          id={option.id}
          className='align-self-end fs-4'
          onChange={onChange}
          value={checked}
        />
        <span role='img' className='m-n2 fs-1'>{option.icon}</span>
        <label
          htmlFor={option.id}
          className='py-3 fw-bold cursor-pointer stretched-link'
        >
          {option.title}
        </label>
        {option.text && <p className='text-small mb-5'>{option.text}</p>}
        {children}
      </Card>
    </Col>
  );
}

NeedInputCard.propTypes = propTypes;
export default NeedInputCard;
