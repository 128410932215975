import React, { useEffect, useState } from 'react';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import Modal from '@/components/Modal';
import Alert from '@/components/Alert';
import classnames from 'classnames';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { useStoredState } from '@/hooks';
import Authorization from '@/components/Authorization';
import { canEditCustomField } from '@/policies';

const propTypes = {
  onSubmit: PropTypes.func.isRequired,
  customFields: PropTypes.array,
  sectionTitle: PropTypes.string
};

function BaseReorderFields({ onSubmit, customFields, sectionTitle, id }) {
  const [show, setShow] = useState(false);
  const [newCustomFieldsOrder, setNewCustomFieldsOrder] = useState([]);
  const { value: hasClosed, store: setHasClosed } = useStoredState(
    'has_closed_fields_reorder_alert',
    false
  );

  useEffect(() => {
    setNewCustomFieldsOrder(
      customFields.map((customField) => ({
        id: customField.id,
        name: customField.name,
        order: customField.order
      }))
    );
  }, [customFields]);

  const reorder = (list, startIndex, endIndex) => {
    const newList = [...list];

    const [removed] = newList.splice(startIndex, 1);
    newList.splice(endIndex, 0, removed);

    return newList;
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const reorderedList = reorder(
      newCustomFieldsOrder,
      source.index,
      destination.index
    ).map((item, index) => ({ ...item, order: index + 1 }));

    setNewCustomFieldsOrder(reorderedList);
  };

  const onHide = () => {
    setNewCustomFieldsOrder(customFields);
    setShow(false);
  };

  const handleSubmit = () => {
    onSubmit(newCustomFieldsOrder, () => setShow(false));
  };

  return (
    <div id={id}>
      <Authorization policy={canEditCustomField}>
        <Button variant='light' onClick={() => setShow(true)}>
          <Icon name='order' className='me-1' />
          Reordenar campos
        </Button>
      </Authorization>

      <Modal
        bodyClassName='p-8'
        onHide={onHide}
        show={show}
      >
        <div>
          <h1 className='text-center mb-6'>Ordenar campos</h1>
          {
            !hasClosed && (
              <Alert onClose={() => setHasClosed(true)} variant='light'>
                Por enquanto, é permitido alterar apenas a ordem dos campos customizados.
              </Alert>
            )
          }

          <div>
            <div>
              {
                <DragDropContext onDragEnd={onDragEnd}>
                  <div className='stages-list'>
                    <h2 className='mb-3'>{sectionTitle}</h2>

                    <div className='border-1 border-bottom border-darker-gray' />

                    <Droppable
                      droppableId='custom-fields-order'
                      type='row'
                    >
                      {(droppableProvided, droppableSnapshot) => (
                        <div
                          className={classnames(
                            'max-height-5 overflow-y-auto',
                            { 'pe-none': droppableSnapshot.isDraggingOver }
                          )}
                          ref={droppableProvided.innerRef}
                          {...droppableProvided.droppableProps}
                        >
                          {
                            newCustomFieldsOrder.length > 0
                              ? (
                                newCustomFieldsOrder.map((item, index) => (
                                  <Draggable
                                    draggableId={String(item.id)}
                                    index={index}
                                    key={item.id}
                                    isDragDisabled={item.isDragDisabled}
                                  >
                                    {(draggableProvided, draggableSnapshot) => (
                                      <div
                                        className={classnames(
                                          'stages-item',
                                          'custom-fields-order',
                                          'd-flex',
                                          'align-items-start',
                                          'hover-parent',
                                          'hover-bg-light',
                                          'border-1',
                                          'border-bottom',
                                          'text-nowrap',
                                          { 'bg-light': draggableSnapshot.isDragging }
                                        )}
                                        ref={draggableProvided.innerRef}
                                        {...draggableProvided.draggableProps}
                                      >
                                        <div className='px-3 py-2 text-dark-gray'>
                                          <div className={classnames(
                                            'py-2',
                                            'align-self-center',
                                            'd-flex',
                                            'align-items-center'
                                          )}>
                                            <span
                                              className='draggable'
                                              {...draggableProvided.dragHandleProps}
                                            >
                                              <Icon
                                                name='drag'
                                                className='hover-child-text-body'
                                                withStroke
                                              />
                                            </span>

                                            <div className='ms-2'>
                                              {item.name}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Draggable>
                                ))
                              )
                              : (
                                <div className='text-center mt-4 text-small'>
                                  A ordenação de todos os campos dessa seção estará disponível
                                  em breve! Por enquanto, você pode ordenar campos customizados.
                                </div>
                              )
                          }

                          {droppableProvided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </div>
                </DragDropContext>
              }
            </div>

            <div className='mt-8 d-flex justify-content-end'>
              <Button
                onClick={onHide}
                variant='outline-dark-gray'
                className='me-3'
              >
                Cancelar
              </Button>
              <Button
                className='px-8'
                onClick={handleSubmit}
              >
                Salvar
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

BaseReorderFields.propTypes = propTypes;

export default BaseReorderFields;
