import React, { useMemo } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from '@/components/Button';
import Icon from '@/components/Icon';
import {
  InputPreview,
  AddressFieldsPreview,
  PrivacyFieldPreview,
  CustomFieldsSectionPreview
} from '@/components/CustomFields';
import Main from '@/components/Main';
import { useAuth } from '@/lib/auth';
import { useDocumentTitle } from '@/hooks';
import NotFound from '@/pages/not-found';
import { Select, TextArea, TextInput } from '@/components/Inputs';
import CreateCustomFields from '@/components/CreateCustomFields';
import {
  useCreateOrganizationsCustomField,
  useUpdateOrganizationsCustomField,
  useDeleteOrganizationsCustomField,
  useOrganizationCustomFields
} from '@/api';
import OrganizationsReorderFields from '@/components/settings/OrganizationsReorderFields';

const PAGE_TITLE = 'Campos customizados';

function OrganizationFormPreview() {
  const { user } = useAuth();
  const createOrganizationsCustomFieldMutation = useCreateOrganizationsCustomField();
  const updateOrganizationsCustomFieldMutation = useUpdateOrganizationsCustomField();
  const deleteOrganizationsCustomFieldMutation = useDeleteOrganizationsCustomField();
  const { data: customFields, error, isSuccess } = useOrganizationCustomFields();

  const sections = useMemo(() => customFields?.data.reduce((acc, customField) => {
    const section = customField.section;

    return {
      ...acc,
      [section]: [...acc[section], customField]
    };
  }, { basic_data: [], contact: [], address: [] }), [customFields]);

  useDocumentTitle(PAGE_TITLE);

  if (!user.account.paid || error) {
    return <NotFound />;
  }

  if (!isSuccess) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid={false} >
      <Container>
        <h1>Formulário de empresa</h1>

        <p className='subtitle text-dark-gray'>
          Personalize os formulários do Agendor de acordo com as suas
          necessidades específicas.
        </p>

        <hr className='my-4' />

        <h2 className='my-4'>
          Dados básicos
        </h2>

        <Row>
          <Col sm={12}>
            <InputPreview
              as={TextInput}
              label='Nome'
              variant='light'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='CNPJ'
              variant='light'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Razão social'
              variant='light'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Categoria'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Origem'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Responsável'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={Select}
              label='Setor'
            />
          </Col>
        </Row>

        <Row>
          <Col sm={12}>
            <InputPreview
              as={TextArea}
              label='Descrição'
              variant='light'
            />
          </Col>
        </Row>

        <CustomFieldsSectionPreview
          section='basic_data'
          entity='organization'
          fields={sections.basic_data}
          updateMutation={updateOrganizationsCustomFieldMutation}
          deleteMutation={deleteOrganizationsCustomFieldMutation}
        />

        <div className='d-flex mb-4'>
          <CreateCustomFields
            section='basic_data'
            mutation={createOrganizationsCustomFieldMutation}
            entity='organization'
            className='me-2'
          />

          <OrganizationsReorderFields
            section='basic_data'
            customFields={sections.basic_data}
            sectionTitle='Seção Dados básicos'
          />
        </div>

        <hr className='mb-6' />

        <h2 className='my-2'>
          Informações para contato
        </h2>

        <p className='subtitle text-dark-gray'>
          Adicione informações que facilitem o contato com o cliente.
        </p>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Email'
              variant='light'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Whatsapp'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Telefone'
            />
          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Celular'
            />
          </Col>
        </Row>

        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Fax'
            />

          </Col>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Ramal'
            />
          </Col>
        </Row>
        <Row className='gx-3'>
          <Col sm={6}>
            <InputPreview
              as={TextInput}
              label='Website'
            />
          </Col>

          <CustomFieldsSectionPreview
            section='contact'
            entity='organization'
            useRowContainer={false}
            className='gx-3'
            fields={sections.contact}
            updateMutation={updateOrganizationsCustomFieldMutation}
            deleteMutation={deleteOrganizationsCustomFieldMutation}
          />
        </Row>

        <div className='d-flex mb-4'>
          <CreateCustomFields
            section='contact'
            mutation={createOrganizationsCustomFieldMutation}
            entity='organization'
            className='me-2'
          />

          <OrganizationsReorderFields
            section='contact'
            customFields={sections.contact}
            sectionTitle='Seção Dados de contato'
          />
        </div>

        <hr className='mb-6' />

        <h2 className='my-4'>
          Dados do endereço
        </h2>

        <p className='subtitle text-dark-gray'>
          Adicione a localização do seu cliente.
        </p>

        <AddressFieldsPreview />

        <CustomFieldsSectionPreview
          section='address'
          entity='organization'
          fields={sections.address}
          updateMutation={updateOrganizationsCustomFieldMutation}
          deleteMutation={deleteOrganizationsCustomFieldMutation}
        />

        <div className='d-flex mb-4'>
          <CreateCustomFields
            section='address'
            mutation={createOrganizationsCustomFieldMutation}
            entity='organization'
            className='me-2'
          />

          <OrganizationsReorderFields
            section='address'
            customFields={sections.address}
            sectionTitle='Seção Dados de endereço'
          />
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Produtos e serviços
          </h2>

          <p className='subtitle text-dark-gray'>
            Quais esta empresa tem potencial de compra?
          </p>

          <InputPreview
            as={Select}
            label=''
            placeholder='Buscar...'
          />
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Pessoas da empresa
          </h2>

          <p className='subtitle text-dark-gray'>
            Adicione seus contatos diretos de dentro da empresa.
          </p>

          <Button className='mb-6' disabled>
            <Icon name='plus' size='sm' className='me-1' />
            <span>Adicionar pessoas</span>
          </Button>
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Redes sociais
          </h2>

          <Row className='gx-3'>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Facebook'
                variant='light'
              />
            </Col>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Twitter'
                variant='light'
              />
            </Col>
          </Row>

          <Row className='gx-3'>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='LinkedIn'
                variant='light'
              />
            </Col>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Skype'
                variant='light'
              />
            </Col>
          </Row>

          <Row className='gx-3'>
            <Col sm={6}>
              <InputPreview
                as={TextInput}
                label='Instagram'
                variant='light'
              />
            </Col>
          </Row>
        </div>

        <hr className='mb-6' />

        <div className='opacity-30'>
          <h2 className='my-4'>
            Privacidade
          </h2>

          <p className='subtitle text-dark-gray'>
            Quem pode ver o histórico e editar essa empresa?
          </p>

          <div className='mb-2'>
            <PrivacyFieldPreview />
          </div>
        </div>
      </Container>
    </Main>
  );
}

export default OrganizationFormPreview;
