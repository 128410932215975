import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAuth } from '@/lib/auth';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import FeatureControlPopover from '@/components/FeatureControlPopover';
import strings from '@/strings';

const propTypes = {
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  setShowRenameModal: PropTypes.func.isRequired,
  renameStyle: PropTypes.object.isRequired,
  copyStyle: PropTypes.object.isRequired,
  deleteStyle: PropTypes.object.isRequired,
  hasAccess: PropTypes.bool.isRequired,
  hasFullAccess: PropTypes.bool,
  disabledCreate: PropTypes.bool.isRequired
};

function ProposalModelsDropdownMenu({
  onDuplicate,
  onDelete,
  setShowRenameModal,
  renameStyle,
  copyStyle,
  deleteStyle,
  hasAccess,
  hasFullAccess,
  disabledCreate
}) {
  const { user } = useAuth();
  const tooltipContent = strings.labels.proposal_models.max(user.maxProposalModels);

  return (
    <Dropdown>
      <Dropdown.Toggle
        id='proposal-model-menu-toggle'
        variant='transparent-light'
        className='p-2 mt-n2 me-n2'>
        <Icon name='vertical-dots' className='text-darker-gray' />
      </Dropdown.Toggle>

      <Dropdown.Menu id='proposal-model-menu' renderOnMount className='width-5'>
        <Dropdown.Item
          as='button'
          className={renameStyle.textClass}
          onClick={() => setShowRenameModal(true)}
          disabled={!hasAccess}
        >
          {renameStyle.iconClass}
          Renomear
        </Dropdown.Item>

        <Dropdown.Divider />

        <FeatureControlPopover user={user} showPopover={!hasFullAccess}>
          <div
            className={`${copyStyle.textClass} px-4 py-3 cursor-pointer hover-bg-light`}
            onClick={onDuplicate}
          >
            <Tooltip
              hide={!disabledCreate}
              placement='top'
              content={tooltipContent}
            >
              <div className={classnames(
                { 'opacity-50 cursor-default': disabledCreate }
              )}>
                {copyStyle.iconClass}
                  Duplicar
              </div>
            </Tooltip>
          </div>
        </FeatureControlPopover>

        <Dropdown.Divider />

        <Dropdown.Item
          as='button'
          className={deleteStyle.textClass}
          onClick={onDelete}
          disabled={!hasAccess}
        >
          {deleteStyle.iconClass}
          Excluir
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

ProposalModelsDropdownMenu.propTypes = propTypes;

export default ProposalModelsDropdownMenu;
