import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useAlert } from 'react-alert';
import classnames from 'classnames';
import Stack from 'react-bootstrap/Stack';
import Button from '@/components/Button';
import Card from '@/components/Card';
import Icon from '@/components/Icon';
import { Switch } from '@/components/Inputs';
import { templates, SummaryContent } from '@/feature/automation';
import { formatDate } from '@/date';
import { useDeleteAutomation, useUpdateAutomation } from '@/api';
import APIErrorMessage from '@/components/APIErrorMessage';
import DeleteModal from '@/feature/automation/DeleteModal';
import Authorization from '@/components/Authorization';
import { canUpdateAutomation, canDeleteAutomation } from '@/policies/automations';
import FeatureControlPopover from '@/components/FeatureControlPopover';
import { useAuth } from '@/lib/auth';

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number.isRequired,
    active: PropTypes.bool.isRequired
  }).isRequired
};

function AutomationCard({ data }) {
  const alert = useAlert();
  const type = `${data.triggerName}_${data.actionName}`;
  const template = templates[type] ?? {};
  const { user } = useAuth();
  const hasAccess = user.account.legacyPro || user.account.performanceOrHigher;

  const updateMutation = useUpdateAutomation();
  const onChangeActive = (newValue) => {
    updateMutation.mutate({ id: data.id, params: { active: newValue } }, {
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='automation' action='update' />,
          { variant: 'danger', timeout: 5000 }
        );
      },
      onSuccess: () => {
        alert.show(
          'Automação atualizada com sucesso!',
          { variant: 'success', timeout: 5000 }
        );
      }
    });
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const onDelete = () => setShowDeleteModal(true);
  const onHideDelete = () => setShowDeleteModal(false);
  const deleteMutation = useDeleteAutomation({
    config: {
      onSuccess: () => {
        alert.show(
          'Automação excluída com sucesso',
          { variant: 'success', timeout: 5000 }
        );
      },
      onError: (err) => {
        alert.show(
          <APIErrorMessage err={err} resource='automation' action='delete' />,
          { variant: 'danger' }
        );
        onHideDelete();
      }
    }
  });
  const onConfirmDelete = () => deleteMutation.mutateAsync({ id: data.id });
  const isDeleting = deleteMutation.isLoading;

  return (
    <FeatureControlPopover user={user} showPopover={!hasAccess}>
      <Card
        className={classnames(
          'p-4',
          'border-1',
          'h-100',
          { 'bg-lighter-gray opacity-50': !data.active }
        )}
      >
        <Stack direction='horizontal' gap={3} className='align-items-start h-100'>
          <div className='p-2 bg-light rounded'>
            <Icon name={template.triggerIcon} className='text-dark-gray' />
          </div>

          <Stack gap={3} className='width-5'>
            <SummaryContent
              data={data}
              template={template}
              className='flex-1'
            />

            <Card.Content>
              Criada em {formatDate(data.createdAt)}
            </Card.Content>

            <Stack direction='horizontal' gap={3}>
              <Switch
                onChange={onChangeActive}
                value={data.active}
                name='active'
                label={data.active ? 'Ativa' : 'Inativa'}
                disabled={hasAccess ? updateMutation.isLoading : true}
                reverse={false}
                className='mb-0'
              />
              <Authorization policy={canUpdateAutomation}>
                <Button
                  className='p-0'
                  variant='link'
                  href='edit'
                  state={{ id: data.id }}
                  aria-label='Editar'
                  disabled={!hasAccess}
                >
                  <Icon
                    name='edit-field'
                    className={classnames(
                      'cursor-pointer',
                      'ms-auto',
                      'text-dark-gray'
                    )}
                  />
                </Button>
              </Authorization>
              <Authorization policy={canDeleteAutomation}>
                <Button
                  className='p-0'
                  variant='link'
                  onClick={onDelete}
                  aria-label='Excluir'
                  disabled={!hasAccess}
                >
                  <Icon name='delete' className='text-dark-gray' />
                </Button>
              </Authorization>
            </Stack>
          </Stack>
        </Stack>
      </Card>

      <DeleteModal
        show={showDeleteModal}
        onConfirm={onConfirmDelete}
        onHide={onHideDelete}
        loading={isDeleting}
        disabled={isDeleting}
      />
    </FeatureControlPopover>
  );
}

AutomationCard.propTypes = propTypes;

export default AutomationCard;
