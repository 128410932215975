import React from 'react';
import PropTypes from 'prop-types';
import success from 'images/success.png';
import Modal from '@/components/Modal';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

ShareProposalModal.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  url: PropTypes.string,
  onOpenEmailModal: PropTypes.func
};

function ShareProposalModal({ show, onClose, url, onOpenEmailModal }) {
  const handleDownloadPDF = () => {
    window.open(url, '_blank');
    onClose();
  };

  return (
    <Modal
      show={show}
      onHide={onClose}
      size='md'
      bodyClassName='p-8'
      backdrop='static'
    >
      <div className='pt-3 px-4 text-center'>
        <img className='mb-4' src={success} alt='Proposta salva no histórico de atividades' />
        <h4>Proposta salva no histórico de atividades</h4>
        <p className='pt-4 text-center'>Que tal compartilhar a proposta com o seu cliente?</p>
      </div>

      <div className='mt-5 d-grid gap-3 w-75 mx-auto'>
        <Button
          variant='light'
          onClick={onOpenEmailModal}
          className='fw-bold'
          aria-label='Enviar por e-mail'
        >
          <Icon name='email' size='sm' className='me-1' />
          Enviar por e-mail
        </Button>
        <Button
          variant='light'
          className='fw-bold'
          aria-label='Baixar PDF'
          onClick={handleDownloadPDF}
        >
          <Icon name='download' size='sm' className='me-1' />
          Baixar PDF
        </Button>
        <Button
          variant='link'
          onClick={onClose}
          className='fw-bold'
          aria-label='Enviar depois'
        >
          Enviar depois
        </Button>
      </div>
    </Modal>
  );
}

export default ShareProposalModal;
