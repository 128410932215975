import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useAlert } from 'react-alert';
import { useDocumentTitle } from '@/hooks';
import { redirect, setLegacyAlertMessage } from '@/browser';
import {
  legacyNewFormsOptoutUrl,
  legacyOrganizationsUrl,
  legacyOrganizationUrl,
  organizationPath,
  settingsCustomFieldsPath
} from '@/routes';
import { useAuth } from '@/lib/auth';
import { useTracking } from '@/lib/tracking';
import { useCreateOrganization, useOrganizationCustomFields } from '@/api';
import { getValidationErrors, isFeedbackRecent, isOptin } from '@/utils';
import APIErrorMessage from '@/components/APIErrorMessage';
import FullOrganizationForm from '@/components/FullOrganizationForm';
import Main from '@/components/Main';
import OptoutButton from '@/components/OptoutButton';
import BackToLegacyModal from '@/components/EntityModal/BackToLegacyModal';
import Button from '@/components/Button';
import Icon from '@/components/Icon';

const PAGE_TITLE = 'Adicionar nova empresa';

function OrganizationsNew() {
  const createOrganizationMutation = useCreateOrganization();
  const alert = useAlert();
  const { user } = useAuth();
  const navigate = useNavigate();
  const tracker = useTracking();
  const { data: customFields, isSuccess: loadedCustomFields } = useOrganizationCustomFields();
  const eventMetadata = { source: 'newForm', entityCustomFields: customFields?.data, user };
  const [showLegacyModal, setShowLegacyModal] = useState(false);

  useDocumentTitle(PAGE_TITLE);

  useEffect(() => {
    tracker.trackNewCreateOrganizationFormViewed({ user });
  }, [user]);

  const handleOpenModal = () => {
    const screen = 'Formulario';
    const feedbackGivenRecently = isFeedbackRecent(screen);
    tracker.trackBackToLegacy({
      user,
      page: 'Formulario',
      entity: 'Empresa',
      feedback: !feedbackGivenRecently
    });
    if (feedbackGivenRecently) {
      const redirectUrl = legacyNewFormsOptoutUrl({ type: 'organization' });
      window.location.href = redirectUrl;
    } else {
      setShowLegacyModal(true);
    }
  };

  const handleSubmit = (formData, { setFieldError, setSubmitting }) => {
    createOrganizationMutation.mutate(formData, {
      onError: (err) => {
        const errors = getValidationErrors(err, 'organization');
        Object.entries(errors).forEach(([key, value]) => {
          setFieldError(key, value);
        });
        alert.show(
          <APIErrorMessage err={err} resource='organization' action='create' />,
          { variant: 'danger' }
        );
        setSubmitting(false);
      },
      onSuccess: ({ data }) => {
        tracker.trackOrganizationCreated({ ...eventMetadata, formData });

        if (formData.people?.length) {
          tracker.trackPersonCreated({
            ...eventMetadata,
            quickAdded: true
          });
        }

        setLegacyAlertMessage('Empresa inserida com sucesso!', 'success');
        if (user.features.blockLegacyEnabled || isOptin(user?.features?.organizationModalEnabled)) {
          navigate(organizationPath(data.id, '/organizations'));
        } else {
          redirect(legacyOrganizationUrl(data.id));
        }
      }
    });
  };

  const initialValues = useMemo(() => ({
    owner_user_id: user.id,
    privacy: { type: user.account.privacy }
  }), [user]);


  const handleCancel = () => redirect(legacyOrganizationsUrl());


  if (!loadedCustomFields) {
    return <div className='flex-grow-1 bg-light' />;
  }

  return (
    <Main fluid flex>
      <div className='py-6 border-bottom'>
        <Container>
          <Row>
            <Col
              md={{ offset: 1, span: 10 }}
              className='d-flex align-items-center'
            >
              <div className='flex-fill'>
                <h1>{PAGE_TITLE}</h1>
              </div>

              <Button
                variant='light'
                href={settingsCustomFieldsPath('organizations')}
                className='text-primary'
              >
                <Icon name='config' className='me-1' />
                Personalize este formulário
              </Button>
              <OptoutButton
                from='newForms'
                className='ms-2'
                entity={{ type: 'organization' }}
                onClick={handleOpenModal}
              />
            </Col>
          </Row>
        </Container>
      </div>

      <BackToLegacyModal
        show={showLegacyModal}
        onHide={() => setShowLegacyModal(false)}
        variant='newForms'
        entity={{ type: 'organization' }}
        entityType={'organization'}
      />

      <FullOrganizationForm
        initialValues={initialValues}
        customFields={customFields?.data ?? []}
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        saveButtonText='Salvar'
        cancelButtonText='Voltar'
      />
    </Main>
  );
}

export default OrganizationsNew;
