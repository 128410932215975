import React from 'react';
import BaseReorderFields from '@/components/settings/BaseReorderFields';
import { useUpdateDealsCustomFieldOrder } from '@/api';
import { useAlert } from 'react-alert';

function DealsReorderFields({ customFields, section, ...props }) {
  const alert = useAlert();
  const updateDealsCustomFieldOrder = useUpdateDealsCustomFieldOrder();

  const onSubmit = (data, callback) => {
    updateDealsCustomFieldOrder
      .mutate({ params: { custom_fields: data } }, {
        onSuccess: () => {
          alert.show(
            'Personalização feita com sucesso!',
            { variant: 'success', timeout: 5000 }
          );
        },
        onError: () => {
          alert.show(
            'Erro ao personalizar',
            { variant: 'danger', timeout: 5000 }
          );
        },
        onSettled: () => callback()
      });
  };

  return (
    <BaseReorderFields
      id={`reorder-${section}`}
      customFields={customFields}
      onSubmit={onSubmit}
      {...props}
    />
  );
}

export default DealsReorderFields;
