import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node
};

const titles = {
  schedule_activity: 'Agendar atividade automaticamente',
  create_deal: 'Criar negócio automaticamente',
  send_email: 'Enviar e-mail automaticamente'
};

function Section(props) {
  const { name, children } = props;

  const title = titles[name] ?? 'Automações criadas';

  return (
    <section>
      <h4 className='my-4'>{title}</h4>

      <div className='d-flex flex-wrap gap-4'>
        {children}
      </div>

      <hr className='mt-2' />
    </section>
  );
}

Section.propTypes = propTypes;

export default Section;
