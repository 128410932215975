import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useAuth } from '@/lib/auth';
import Button from '@/components/Button';
import LoadingButton from '@/components/LoadingButton';
import ProposalLoading from '@/components/Proposal/ProposalLoading';
import Dropdown from '@/components/Dropdown';
import Icon from '@/components/Icon';
import Tooltip from '@/components/Tooltip';
import FeatureControlPopover from '@/components/FeatureControlPopover';
import strings from '@/strings';

ProposalPreview.propTypes = {
  preview: PropTypes.string,
  saving: PropTypes.bool,
  update: PropTypes.bool,
  onCancel: PropTypes.func,
  onCreateProposal: PropTypes.func,
  onCreateProposalModel: PropTypes.func,
  onUpdateProposalModel: PropTypes.func,
  onCopyProposalModel: PropTypes.func,
  isDisabledCreate: PropTypes.bool
};

ProposalPreview.defaultProps = {
  preview: {},
  saving: false,
  update: false,
  onCancel: () => { },
  onCreateProposal: () => { },
  onCreateProposalModel: () => { },
  onUpdateProposalModel: () => { },
  onCopyProposalModel: () => { },
  isDisabledCreate: false
};

const ACTIONS = {
  CREATE_PROPOSAL: 'CreateProposal',
  CREATE_PROPOSAL_MODEL: 'CreateProposalModel',
  UPDATE_PROPOSAL_MODEL: 'UpdateProposalModel',
  COPY_PROPOSAL_MODEL: 'CopyProposalModel'
};

function ProposalPreview(
  {
    preview,
    saving,
    update,
    onCancel,
    onCreateProposal,
    onCreateProposalModel,
    onUpdateProposalModel,
    onCopyProposalModel,
    isDisabledCreate
  }
) {
  const { user } = useAuth();
  const tooltipContent = strings.labels.proposal_models.max(user.maxProposalModels);
  const hasFullAccess = user.account.legacyPro || user.account.performanceOrHigher;
  const [actionButton, setActionButton] = useState();

  const handleCreateProposal = () => {
    setActionButton(ACTIONS.CREATE_PROPOSAL);
    onCreateProposal();
  };

  const handleUpdateProposalModel = () => {
    setActionButton(ACTIONS.UPDATE_PROPOSAL_MODEL);
    onUpdateProposalModel();
  };

  const handleCreateProposalModel = () => {
    if (hasFullAccess && !isDisabledCreate) {
      setActionButton(ACTIONS.CREATE_PROPOSAL_MODEL);
      onCreateProposalModel();
    }
  };

  const handleCopyProposalModel = () => {
    if (hasFullAccess && !isDisabledCreate) {
      setActionButton(ACTIONS.COPY_PROPOSAL_MODEL);
      onCopyProposalModel();
    }
  };

  const isCreateProposal = saving && actionButton === ACTIONS.CREATE_PROPOSAL;
  const isCreateProposalModel = saving && actionButton === ACTIONS.CREATE_PROPOSAL_MODEL;
  const isUpdateProposalModel = saving && actionButton === ACTIONS.UPDATE_PROPOSAL_MODEL;
  const isCopyProposalModel = saving && actionButton === ACTIONS.COPY_PROPOSAL_MODEL;

  const isUpdateOrCopyProposalModel = isUpdateProposalModel || isCopyProposalModel;
  const isCreateProposalOrProposalModel = isCreateProposal || isCreateProposalModel;

  const savingText = 'Salvando...';
  const copyingText = 'Duplicando...';
  const saveOrCopyText = isUpdateProposalModel ? savingText : copyingText;

  return (
    <>
      {
        isCreateProposal
          ? (
            <ProposalLoading
              title={'Criando a proposta'}
              subtitle={'Aguarde só um pouco, estamos salvando a proposta.'}
            />
          )
          : (
            <object
              data={preview}
              type='application/pdf'
              className='proposal-preview'
            />
          )
      }
      <div className='position-sticky bg-white shadow-up bottom-0 left-0 w-100 z-index-1060'>
        <div className='d-flex justify-content-end px-8 me-2 py-6'>
          <Button
            onClick={onCancel}
            disabled={saving}
            className='me-2'
            variant='light'
          >
            Voltar
          </Button>
          {
            update
              ? (
                <>
                  <Dropdown className='me-2' drop='up'>
                    <Dropdown.Toggle
                      as={LoadingButton}
                      disabled={saving}
                      isLoading={isUpdateOrCopyProposalModel}
                      loadingText={saveOrCopyText}
                      variant='light'
                    >
                      Salvar modelo
                      <Icon name='arrow-down' className='ms-1' />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item
                        as='button'
                        disabled={saving}
                        onClick={handleUpdateProposalModel}
                      >
                        <Icon name='upload' className='me-2' />
                        <span className='fw-bold'>Salvar alterações</span>
                      </Dropdown.Item>
                      <Dropdown.Divider />
                      <FeatureControlPopover
                        user={user}
                        showPopover={!hasFullAccess}
                        flip
                      >
                        <Tooltip
                          hide={!isDisabledCreate}
                          placement='top'
                          content={tooltipContent}
                        >
                          <div className={classnames(
                            'px-4 py-3 text-nowrap cursor-pointer hover-bg-light rounded-bottom',
                            { 'opacity-50': isDisabledCreate }
                          )}
                          disabled={saving || isDisabledCreate}
                          onClick={handleCopyProposalModel}
                          >
                            <Icon name='plus' className='me-2' />
                            <span className='fw-bold'>Salvar como cópia</span>
                          </div>
                        </Tooltip>
                      </FeatureControlPopover>
                      <Dropdown.Divider />
                    </Dropdown.Menu>
                  </Dropdown>
                  <LoadingButton
                    disabled={saving}
                    isLoading={isCreateProposal}
                    loadingText={savingText}
                    onClick={handleCreateProposal}
                  >
                    Salvar e enviar
                  </LoadingButton>
                </>
              )
              : (
                <Dropdown className='me-4' drop='up'>
                  <Dropdown.Toggle
                    as={LoadingButton}
                    disabled={saving}
                    isLoading={isCreateProposalOrProposalModel}
                    loadingText={savingText}
                    variant='primary'
                  >
                    Salvar
                    <Icon name='arrow-down' className='ms-1' />
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      as='button'
                      disabled={saving}
                      onClick={handleCreateProposal}
                    >
                      <Icon name='send' className='me-2' size='sm' />
                      <span className='fw-bold'>Salvar e enviar</span>
                    </Dropdown.Item>
                    <Dropdown.Divider />
                    <FeatureControlPopover
                      user={user}
                      showPopover={!hasFullAccess}
                      flip
                    >
                      <Tooltip
                        hide={!isDisabledCreate}
                        placement='top'
                        content={tooltipContent}
                      >
                        <div
                          className={classnames(
                            'p-3 cursor-pointer text-nowrap hover-bg-light rounded-bottom',
                            { 'opacity-50': isDisabledCreate }
                          )}
                          disabled={saving || isDisabledCreate}
                          onClick={handleCreateProposalModel}
                        >
                          <Icon name='proposal' className='me-2' />
                          <span className='fw-bold'>Salvar como modelo</span>
                        </div>
                      </Tooltip>
                    </FeatureControlPopover>
                  </Dropdown.Menu>
                </Dropdown>
              )
          }
        </div>
      </div>
    </>
  );
}

export default ProposalPreview;
