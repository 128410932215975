export function canHideFields({ user }) {
  let effect, reason;
  const planName = 'Performance';

  if (!user.account.legacyPro && !user.account.performanceOrHigher) {
    effect = 'paywall';
    reason = 'Para ocultar campos da proposta, é necessário contratar o Plano Performance.';
  } else {
    effect = 'allow';
  }

  return { effect, reason, planName };
}
